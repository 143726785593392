/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *MessagingMixin.js*
 *
 * ### Content
 *   * mixin containing basic functionality for messaging (except logging)
 *   * provides a standard messaging function which logs to the console
 *
 * @module MessagingMixin
 * @requires module:LoggingMixin
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Import messaging constants
 */
var messagingConstants = require('../constants/MessagingConstants');

/**
 * Import logging constants
 */
var loggingConstants = require('../constants/LoggingConstants');

/**
 * Constructor of the messaging mixin
 * @mixin MessagingMixin
 * @author Alex Schiftner <alex@shapediver.com>
 * @requires module:LoggingMixin
 */
var MessagingMixin = function() {

  var that = this;

  ////////////
  ////////////
  //
  // Messaging
  //
  ////////////
  ////////////

  /**
   * Send message to log stream
   * This function will typically be used for debugging only
   * @public
   * @param {module:MessagingConstants~MessageTopic} topic - topic to be used, static parts of topics shall be assembled from MessagingConstants.messageTopics
   * @param {module:MessagePrototype~MessagePrototype} msg - message object
   */
  this.messageToLog = function(scope, msg) {
    let loggingLevel = that.getSetting('messageLoggingLevel');
    if ( loggingLevel !== loggingConstants.loggingLevels.NONE )
      that.log( loggingLevel, scope, msg );
  };

  /**
   * Send message to pub/sub
   * will typically by used by ViewerApp, but is not used by default
   * @public
   * @param {module:MessagingConstants~MessageTopic} topic - topic to be used, static parts of topics shall be assembled from MessagingConstants.messageTopics
   * @param {module:MessagePrototype~MessagePrototype} msg - message object
   * @param {Object} pubsub - pub/sub object to be used
   * @param {Object} [options] - optional options object
   * @param {String} [options.prefix] - optional prefix for topic
   * @param {String} [options.postfix] - optional postfix for topic
   */
  this.messageToPubSub = function(scope, msg, pubsub, options) {
    if ( options && typeof options.prefix === 'string'  )
      scope = options.prefix.concat('.', scope);
    if ( options && typeof options.postfix === 'string'  )
      scope = scope.concat('.', options.postfix);
    pubsub.publish(scope, msg);
  };

  /**
   * Default function for sending messages, makes use of messageToLog.
   *
   * This function will typically be used for debugging only and be replaced by ViewerApp.
   * @public
   * @param {module:MessagingConstants~MessageTopic} topic - topic to be used, static parts of topics shall be assembled from MessagingConstants.messageTopics
   * @param {module:MessagePrototype~MessagePrototype} msg - message object
   */
  this.message = function(scope, msg) {
    that.messageToLog( scope, msg );
  };

  // set message logging level if none is set yet
  if ( !this.hasSetting('messageLoggingLevel') )
    that.updateSetting('messageLoggingLevel', messagingConstants.defaultSettings.messageLoggingLevel);

  return this;
};

// here we could define public functions
//MessagingMixin.prototype.

// export the constructor
module.exports = MessagingMixin;
