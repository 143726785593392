/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *DispatchingConstants.js*
 *
 * ### Content
 *   * Definition of constants related to the dispatching configuration of the ViewerApp
 *
 * @module DispatchingConstants
 * @author Alex Schiftner <alex@shapediver.com>
 */


/**
 * Callback parameter types
 * @typedef {String} CallbackParamType
 */

/**
 * Possible (currently implemented) callback parameter types
 * @typedef {Object} CallbackParamTypes
 * @property {CallbackParamType} TYPE - will pass the message data type {@link module:MessagingConstants~MessageDataType}
 * @property {CallbackParamType} DATA - will pass the message data part {@link module:MessagingConstants~MessageDataPart}
 * @property {CallbackParamType} TOKEN - will pass the token {@link module:MessagingConstants~MessageToken}
 * @property {CallbackParamType} ORIGIN - will pass the origin member of the message {@link module:MessagingConstants~MessageOrigin}
 * @property {CallbackParamType} TOPIC - will pass the topic of the message
 * @property {CallbackParamType} PLUGIN - will pass the plugin runtime id contained in the origin object, if any, otherwise undefined
 */
var callbackParamTypes = {
  TYPE: 'type',
  DATA: 'data',
  TOKEN: 'token',
  ORIGIN: 'origin',
  TOPIC: 'topic',
  PLUGIN: 'plugin'
};

/**
 * Default callback parameter types
 * @public
 * @type {CallbackParamType[]}
 */
var callbackParamTypesDefault = [
  callbackParamTypes.TOKEN,
  callbackParamTypes.DATA,
  callbackParamTypes.TYPE,
  callbackParamTypes.ORIGIN
];


/**
 * Collection of all constants
 */
var dispatchingConstants = {
  callbackParamTypes: callbackParamTypes,
  callbackParamTypesDefault: callbackParamTypesDefault
};

/**
 * Global utilities
 */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
 * Export a deep copy of the constants
 */
module.exports = GlobalUtils.deepCopy(dispatchingConstants);
