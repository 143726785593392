/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiViewportsInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer Viewports API V2.1
 *
 * @module ApiViewportsInterface
 * @author ShapeDiver <contact@shapediver.com>
 */


////////////
////////////
//
// Viewport Interface
//
////////////
////////////

/**
  * Viewport event type, see {@link module:ApiViewportsInterface~ApiViewportsInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiViewportsInterface~ApiViewportsInterface#ViewportEventType
  */

/**
 * @typedef module:ApiViewportsInterface~ApiViewportsInterface#ViewportDefinition
 * @property {HTMLElement} container the domElement in which to put the viewer
 * @property {String} runtimeId a unique runtimeId, if the id is not unique, a new one will be created
 */


/**
 *
 * ```
 * // container for the viewport, typically this is a <div>
 * var _container = document.getElementById('sdv-container-viewport');
 * let viewportApi = api.viewports.create({
 *   container: _container,
 *   runtimeID: 'defaultViewport'
 * });
 *
 * // to get the camera definition
 * viewportApi.camera.get();
 *
 * // get a screenshot of the current viewport
 * viewportApi.getScreenshot();
 *
 * // if there is only one default viewport
 * // api.scene.camera.get();
 * // and
 * // api.scene.getScreenshot();
 * // would do exactly the same
 * ```
 *
 * The api for a specific viewport. If there is only one viewport {@link module:ApiSceneInterface~ApiSceneInterface api.scene} can be used instead.
 * Due to the implementation being identical to {@link module:ApiSceneInterface~ApiSceneInterface api.scene} but only viewport specific, the documentation is omitted. Please see {@link module:ApiSceneInterface~ApiSceneInterface api.scene} instead.
 *
 * The viewportApi has a camera api ({@link module:CameraApiDefault~CameraApi viewportApi.camera}) and a lights api ({@link module:LightApiDefault~LightApi viewportApi.lights}) that have the same methods as the corresponding apis in {@link module:ApiSceneInterface~ApiSceneInterface api.scene}.
 * The specific documentation is omitted. Please see {@link module:CameraApiDefault~CameraApi api.scene.camera} or {@link module:LightApiDefault~LightApi api.scene.lights}.
 *
 * @typedef module:ApiViewportsInterface~ApiViewportsInterface#ViewportApi
 * @property {module:CameraApiDefault~CameraApi} camera the camera api
 * @property {module:LightApiDefault~LightApi} lights the light api
 */

/**
 * ### ShapeDiver Viewer - Viewport API
 *
 * The viewports interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
 *
 * It allows to
 *  * get viewport apis
 *  * create and destroy viewports
 *
 * @interface ApiViewportsInterface
 */
var ApiViewportsInterface = function () {
  /**
   * Enum for the viewport api events.
   * @readonly
   * @enum {module:ApiViewportsInterface~ApiViewportsInterface#ViewportEventType}
   */
  this.EVENTTYPE = {
    /** a viewport has been created */
    CREATED: 'created',
    /** the viewport has been destroyed */
    DESTROYED: 'destroyed',
  };

  /**
   * Get api for existing viewports
   *
   * @param {String} runtimeId the runtimeId of the viewport, or undefined to return an object containing apis for all viewports
   * @return {module:ApiViewportsInterface~ApiViewportsInterface#ViewportApi} the api of the viewport with the given runtimeId, or an array containing apis for all viewports
   */
  this.get = function () { };

  /**
   * Creates a new viewport
   *
   * @param {module:ApiViewportsInterface~ApiViewportsInterface#ViewportDefinition} viewportDefinition definition of the viewport
   * @return {module:ApiViewportsInterface~ApiViewportsInterface#ViewportApi} the api of the new viewport
   */
  this.create = function () { };

  /**
   * Destroys an existing viewport
   *
   * @param {String} runtimeId the runtimeId of the viewport
   * @return {boolean} true if there was a viewport to remove
   */
  this.destroy = function () { };

};

module.exports = ApiViewportsInterface;