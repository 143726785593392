/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *PluginConstantsGlobal.js*
 *
 * ### Content
 *   * definition of global plugin constants
 *
 * @module PluginConstantsGlobal
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Plugin capability type
 * @typedef {Number} PluginCapability
 */

/**
 * Possible plugin capabilities
 * @typedef {Object} PluginCapabilities
 * @property {PluginCapability} NONE - the plugin can't do anything, this serves as a placeholder for {@link PluginPrototype#capabilities}
 * @property {PluginCapability} SHAPEDIVER_MODEL_VIEW - implements communication with a ShapeDiver model view server
 * @property {PluginCapability} PARTIAL_UPDATES - supports partial parameter updates
 * @property {PluginCapability} INTERACTIVE - supports interactive geometry updates, therefore needs access to the sceneGeometryManagers's functionality for adding/removing geometry
 * @property {PluginCapability} DRAGANDDROP - supports drag & drop
 * @property {PluginCapability} MESSAGECALLBACKS - provides callbacks for certain messages
 * @property {PluginCapability} LOGGING - provides a handler 'logToServer' to receive log messages
 * @property {PluginCapability} SETTINGS - provides a handler 'saveSettings' for storing settings
 * @property {PluginCapability} DEFAULTPARAM - provides a handler 'saveParameterDefaultValues' for storing default parameter values
 */
var pluginCapabilities = {
  NONE: 0,
  SHAPEDIVER_MODEL_VIEW: 1,
  INTERACTIVE: 2,
  DRAGANDDROP: 3,
  MESSAGECALLBACKS: 4,
  LOGGING: 5,
  SETTINGS: 6,
  DEFAULTPARAM: 7
};


/**
 * Plugin status type
 * @typedef {Number} PluginStatus
 */

/**
 * Possible plugin statuses
 * @typedef {Object} PluginStatuses
 * @property {PluginStatus} NONE - the plugin instance was created, a unique runtime id is not available yet
 * @property {PluginStatus} READY - the plugin instance got a unique runtime id and is ready to load
 * @property {PluginStatus} FAILED - the plugin instance failed for whatever reason and is not usable
 * @property {PluginStatus} LOADED - the plugin instance successfully loaded, i.e. {@link module:PluginInterface~PluginInterface.load} succeeded
 * @property {PluginStatus} ACTIVE - plugin is operating, i.e. any potential initialization that was triggered by {@link module:PluginInterface~PluginInterface.load} succeeded
 * @property {PluginStatus} UNLOADED - plugin was unloaded and is ready to be garbage collected
 */
var pluginStatuses = {
  NONE: 'none',
  READY: 'ready',
  FAILED: 'failed',
  LOADED: 'loaded',
  ACTIVE: 'active',
  UNLOADED: 'unloaded'
};

/**
  * Plugin state description
  * @typedef {Object} PluginStatusDescription
  * @property {String} id - runtime id of the plugin instance
  * @property {module:ApiInterfaceV2~PluginStatus} status - status of the plugin instance
  * @property {String} name - name of the plugin
  * @property {String} shortName - short name of the plugin
  *
  */

/**
 * Plugin update (set parameter) result
 * @typedef {Number} SetParameterResult
 */

/**
 * Possible results of setting a parameter value
 * @typedef {Object} SetParameterResults
 * @property {SetParameterResult} PARAM_NOT_FOUND - the parameter was not found
 * @property {SetParameterResult} NO_VALUE - no value was given
 * @property {SetParameterResult} VALUE_REJECT - the new parameter value was rejected
 * @property {SetParameterResult} VALUE_EXISTS - the parameter value had already been set
 * @property {SetParameterResult} VALUE_OK - the new parameter value was accepted
 * @property {SetParameterResult} PLUGIN_NOT_FOUND - the plugin responsible to handle the parameter update was not found
 * @property {SetParameterResult} UPLOAD_FAILED - upload of parameter data (e.g. blob or file data) failed
 * @property {SetParameterResult} ERROR - the parameter update failed
 * @property {SetParameterResult} LIVE - the parameter update results in a live update
 * @property {SetParameterResult} CACHE - the parameter update results in an update which can be answered from the local cache
 * @property {SetParameterResult} LOAD - the parameter update results in an update which needs to be loaded from somewhere
 * @property {SetParameterResult} ABORT - the parameter update was aborted, it was superseded by another update
 */
var setParameterResults = {
  PARAM_NOT_FOUND: 'param_not_found',
  NO_VALUE: 'no_value',
  VALUE_REJECT : 'value_reject',
  VALUE_EXISTS : 'value_exists',
  VALUE_OK : 'value_ok',
  PLUGIN_NOT_FOUND: 'plugin_not_found',
  UPLOAD_FAILED: 'upload_failed',
  ERROR: 'error',
  LIVE: 'live',
  CACHE: 'cache',
  LOAD: 'load',
  ABORT: 'abort'
};


/**
 * Export request result
 * @typedef {Number} RequestExportResult
 */

/**
 * Possible results of requesting an export
 * @typedef {Object} RequestExportResults
 * @property {RequestExportResult} EXPORT_NOT_FOUND - the export was not found
 * @property {RequestExportResult} PLUGIN_NOT_FOUND - the plugin responsible to handle the export request was not found
 * @property {RequestExportResult} ERROR - the export request failed
 * @property {RequestExportResult} CACHE - the export request can be answered from the local cache
 * @property {RequestExportResult} LOAD - the export request needs to be loaded from somewhere
 * @property {RequestExportResult} ABORT - the export request was aborted
 */
var requestExportResults = {
  EXPORT_NOT_FOUND: 'export_not_found',
  PLUGIN_NOT_FOUND: 'plugin_not_found',
  ERROR: 'error',
  CACHE: 'cache',
  LOAD: 'load',
  ABORT: 'abort'
};

/**
 * Collection of all constants
 */
var pluginConstants = {
  pluginCapabilities: pluginCapabilities,
  pluginStatuses: pluginStatuses,
  setParameterResults: setParameterResults,
  requestExportResults: requestExportResults
};

/**
 * Global utilities
 */
var GlobalUtils = require('../util/GlobalUtils');

/**
 * Export a deep copy of the constants
 */
module.exports = GlobalUtils.deepCopy(pluginConstants);
