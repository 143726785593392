/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *MissingImplementationException.js*
 *
 * ### Content
 *   * exception used in interfaces to report missing implementation of abstract functions
 *
 * @module MissingImplementationException
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * MissingImplementationException constructor
 *
 * Exception which we use to report missing implementations of abstract members.
 * @class
 */
var MissingImplementationException = function() {

};

/**
 * Return string representation of exception
 * @return {String} string representation of exception
 */
MissingImplementationException.prototype.toString = function() {
  return 'Missing implementation';
};


/**
 * Export a deep copy of the constants
 */
module.exports = MissingImplementationException;
