/**
 * @file Creates a shared interface for all RenderingHandlers.
 *       The RenderingHandlerInterface must be implemented by all RenderingHandlers.
 *       All described methods must be implemented.
 * 
 * @module RenderingHandlerInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the RenderingHandler.
 * The RenderingHandler implements all functionality related to the rendering.
 * 
 * //@interface RenderingHandlerInterface
 */
class RenderingHandlerInterface {

  /**
   * The constructor for the {@link module:RenderingHandlerInterface~RenderingHandlerInterface}.
   * @constructs module:RenderingHandlerInterface~RenderingHandlerInterface
   */
  constructor() { }

  /**
   * Renders the scene depending on the render mode.
   *
   * @abstract
   * @param {Boolean} selfInduced If this a call via processContinuousRenderingList
   */
  render(selfInduced) { }

  /**
   * Renders the scene to a hidden render targer.
   *
   * @abstract
   */
  renderHidden() { }

  /**
   * The canvas was resized, resize the renderer.
   *
   * @abstract
   * @param {Number} width 
   * @param {Number} height 
   */
  onResize(width, height) { }

  /**
   * Return the current size of the renderer.
   *
   * @abstract
   * @return {Object}
   */
  getSize() { }




  /**
   * Call to start the rendering handler.
   * Before this is called, no rendering is allowed.
   * 
   * @abstract
   */
  start() {}
  
  /**
   * Pause the rendering.
   *
   * @abstract
   */
  pause() { }

  /**
   * Resume the rendering.
   *
   * @abstract
   */
  resume() { }

  /**
   * Destroy the renderingHandler.
   *
   * @abstract
   */
  destroy() { }




  /**
   * Get screenshot as a png data URI.
   * Returns a screenshot of the viewer canvas without any control elements as a data URI using the png image format.
   *
   * @abstract
   * @returns {String} Data URI representing the png screeshot image (data:image/png;base64,...).
   */
  getScreenshot() { }

  /**
   * Return the dom elment (or similar).
   *
   * @abstract
   * @returns {*}
   */
  getDomElement() { }

  /**
   * Return the canvas (or similar).
   *
   * @abstract
   * @returns {*}
   */
  getCanvas() { }

  /**
   * Returns the info of the renderer. Check for errors with this.
   *
   * @abstract
   * @returns {Object} the renderer info
   */
  getRendererInfo() { }

  /**
   * Adds or removes a global blur to the viewer canvas
   *
   * @abstract
   * @param {Boolean} blur Whether to add or remove the _blur
   * @param {Object} options Options for adding or removing the blur
   */
  setBlur(blur, options) { }

  /**
   * Register to render continuously.
   * The rendering will continue until the function unregisterForContinuousRendering is called.
   * The rendering may continue even further, if other processes are registered.
   *
   * @abstract
   * @param {String} id An ID to find the process in the list
   * @param {Boolean} rendering If the rendering should be actived, if undefined set to true
   */
  registerForContinuousRendering(id, rendering) { }

  /**
   * Removes the process with the given ID from the _continuousRenderingList.
   * If this was the last process in the list, the continuous rendering will stop.
   *
   * @abstract
   * @param {String} id An ID to find the process in the list
   */
  unregisterForContinuousRendering(id) { }

  /**
   * Check if the continous rendering is active for a certain id.
   *
   * @abstract
   * @param {String} id
   * @returns {Boolean} 
   */
  containsContinuousRendering(id) { }


  /**
   * Updates the shadow map with the next render call.
   *
   * @abstract
   */
  updateShadowMap() { }

  /**
   * Keeps updating the shadow map.
   * 
   * @abstract
   */
  keepUpdatingShadowMap() { }

  /**
   * Stops updating the shadow map.
   * 
   * @abstract
   */
  stopUpdatingShadowMap() { }
}

module.exports = RenderingHandlerInterface;