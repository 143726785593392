
let threeDManagerConstants;
{
  const GLOBAL_UTILS = require('../../shared/util/GlobalUtils'),
    LOGGING_CONSTANTS = require('../../shared/constants/LoggingConstants'),
    LOGGING_LEVELS = LOGGING_CONSTANTS.loggingLevels;

  let cameraViewTypes = {
    MIN: 0,
    PERSPECTIVE: 0,
    TOP: 1,
    BOTTOM: 2,
    RIGHT: 3,
    LEFT: 4,
    BACK: 5,
    FRONT: 6,
    MAX: 6
  };

  let cameraControlsTypes = {
    MIN: 0,
    ORBIT: 0,
    FPS: 1,
    ORTHOGRAPHIC: 2,
    MAX: 2
  };

  let settingsDefinition = {
    camera: {
      autoRotationSpeed: {
        value: 0.0,
      },
      damping: {
        value: 0.1,
      },
      defaults: {
        perspective: {
          value: {
            position: {
              value: {
                x: 0, y: 0, z: 0
              },
            },
            target: {
              value: {
                x: 0, y: 0, z: 0
              },
            }
          },
        },
        orthographic: {
          value: {
            position: {
              value: {
                x: 0, y: 0, z: 0
              },
            },
            target: {
              value: {
                x: 0, y: 0, z: 0
              },
            }
          },
        }
      },
      enableAutoRotation: {
        value: false,
      },
      enableKeyPan: {
        value: false,
      },
      enablePan: {
        value: true,
      },
      enableRotation: {
        value: true,
      },
      enableZoom: {
        value: true,
      },
      fov: {
        value: 45,
      },
      keyPanSpeed: {
        value: 1,
      },
      restrictions: {
        position: {
          cube: {
            value: {
              min: {
                value: {
                  x: -Infinity, y: -Infinity, z: -Infinity
                },
              },
              max: {
                value: {
                  x: Infinity, y: Infinity, z: Infinity
                },
              }
            },
          },
          sphere: {
            value: {
              center: {
                value: {
                  x: 0, y: 0, z: 0
                },
              },
              radius: {
                value: Infinity,
              }
            },
          }
        },
        target: {
          cube: {
            value: {
              min: {
                value: {
                  x: -Infinity, y: -Infinity, z: -Infinity
                },
              },
              max: {
                value: {
                  x: Infinity, y: Infinity, z: Infinity
                },
              }
            },
          },
          sphere: {
            value: {
              center: {
                value: {
                  x: 0, y: 0, z: 0
                },
              },
              radius: {
                value: Infinity,
              }
            },
          }
        },
        rotation: {
          value: {
            minPolarAngle: {
              value: 0,
            },
            maxPolarAngle: {
              value: 180,
            },
            minAzimuthAngle: {
              value: -Infinity,
            },
            maxAzimuthAngle: {
              value: Infinity,
            }
          },
        },
        zoom: {
          value: {
            minDistance: {
              value: 0,
            },
            maxDistance: {
              value: Infinity,
            }
          },
        }
      },
      rotationSpeed: {
        value: 0.5,
      },
      panSpeed: {
        value: 0.5,
      },
      type: {
        value: cameraViewTypes.PERSPECTIVE,
      },
      zoomSpeed: {
        value: 1.0,
      },
    }
  };
  function _hasSubValue(def) {
    if (typeof def === 'object') {
      for (let key in def) {
        if (key === 'value')
          return true;
        if (_hasSubValue(def[key]))
          return true;
      }
    }

    return false;
  }

  function _convert(def, global) {
    for (let key in def) {
      if (key === 'value') {
        if (_hasSubValue(def[key])) {
          return global = _convert(def[key], global);
        } else {
          return def[key];
        }
      } else if (key !== 'desc') {
        global[key] = {};
        global[key] = _convert(def[key], global[key]);
      }
    }
    return global;
  }

  let defaultSettings = {};
  _convert(settingsDefinition, defaultSettings);

  threeDManagerConstants = {
    cameraLegacyDefinition: settingsDefinition,
    cameraLegacyDefaultSettings: defaultSettings,
    loggingLevels: LOGGING_LEVELS,
    cameraViewTypes: cameraViewTypes
  };
}

module.exports = require('../../shared/util/GlobalUtils').deepCopy(threeDManagerConstants);
