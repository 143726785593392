/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *DispatchingConfig.js*
 *
 * ### Content
 *   * Container for a dispatching configuration of the ViewerApp
 *
 * @module DispatchingConfig
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Imported messaging constants
 */
//var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
 * Imported global utilities
 */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
 * Imported dispatching constants
 */
//var dispatchingConstants = require('./DispatchingConstants');


/**
 * Constructor of a dispatching configuration of the ViewerApp
 * @author Alex Schiftner <alex@shapediver.com>
 */
var DispatchingConfig = function() {

  //var that = this;

  /**
   * Dispatching configuration
   */
  var _config = {};

  /**
   * Get dispatching configuration
   */
  this.getConfig = function() {
    return _config;
  };

  /**
   * Define a listener for a message data part
   * @param {module:MessagingConstants~MessageTopic} topic - topic of messages to listen for
   * @param {module:MessagingConstants~MessageDataType} type - message part data type to add the listener for
   * @param {String|Function} fn - name of the callback, or the callback itself. in case of name it must be a member of the ViewerApp, dot notation allowed
   * @param {CallbackParamTypes[]} [params=module:DispatchingConstants#callbackParamTypesDefault] - definition of parameters to be passed to the callback
   * @return {Boolean} true on success, false on error
   */
  this.addPartListener = function(topic, type, fn, params) {

    // check if topic already exists
    if ( !_config.hasOwnProperty(topic) || typeof _config[topic] !== 'object' ) {
      _config[topic] = {};
    }
    let topic_o = _config[topic];

    // check if topic has array member 'partListeners'
    if ( !topic_o.hasOwnProperty('partListeners') || !Array.isArray(topic_o.partListeners) ) {
      topic_o.partListeners = [];
    }

    // create config of new part listener
    let partListener = {};
    partListener.type = type;
    partListener.cb = fn;
    partListener.params = params;

    // add config of new part listener
    topic_o.partListeners.push( partListener );

    return true;
  };

};


module.exports = DispatchingConfig;
