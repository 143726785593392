/**
 * SAO implementation (McGuire et al. 2012)
 * inspired by bhouston and ludobaka previous work

 * @module SaoPass
 * @author Michael Oppitz <michael@shapediver.com>
 */
let THREE = require('../../../externals/three');
let Shaders = require('../shaders/ShaderFile');

/**
 * Constructor of the SaoPass
 * @class
 * @param {Object} ___settings - Instantiation settings
 * @param {Object} ___settings.settings - The default settings object
 * @param {Number} ___settings.width Width of the renderer
 * @param {Number} ___settings.height Height of the renderer
 */
var SaoPass = function (___settings) {

  let _settings = ___settings.settings;

  /**
   * The depth material to compute the depth values in the scene.
   * @type {THREE.MeshDepthMaterial}
   */
  let _depthMaterial = new THREE.MeshDepthMaterial();
  _depthMaterial.depthPacking = THREE.RGBADepthPacking;
  _depthMaterial.blending = THREE.NoBlending;
  _depthMaterial.side = THREE.DoubleSide;

  /**
   * The depth material to compute all normals.
   * @type {THREE.MeshNormalMaterial}
   */
  let _normalMaterial = new THREE.MeshNormalMaterial();
  _normalMaterial.blending = THREE.NoBlending;
  _normalMaterial.side = THREE.DoubleSide;

  /**
   * The different render target for the computations.
   * @type {THREE.WebGLRenderTarget}
   */
  let _saoRenderTarget = new THREE.WebGLRenderTarget(___settings.width, ___settings.height, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat
  });
  let _depthRenderTarget = _saoRenderTarget.clone();
  let _normalRenderTarget = _saoRenderTarget.clone();
  let _blurIntermediateRenderTarget = _saoRenderTarget.clone();

  /**
   * The properties for the Sao shader.
   */
  let _saoShader = {
    defines: {
      SAMPLES: _settings.getSetting('samples')
    },
    uniforms: {
      tDepth: { type: 't', value: null },
      tNormal: { type: 't', value: null },

      cameraNear: { type: 'f', value: 1 },
      cameraFar: { type: 'f', value: 100 },
      cameraProjectionMatrix: { type: 'm4', value: new THREE.Matrix4() },
      cameraInverseProjectionMatrix: { type: 'm4', value: new THREE.Matrix4() },

      scale: { type: 'f', value: .1 },
      kernelRadius: { type: 'f', value: _settings.getSetting('kernelRadius') },
      size: { type: 'v2', value: new THREE.Vector2(___settings.width, ___settings.height) },
      intensity: { type: 'f', value: _settings.getSetting('intensity') }
    },
    attributes: ['position', 'uv'],
    vertexShader: Shaders.basic_vert,
    fragmentShader: Shaders.sao_frag
  };

  /**
   * Creation of the Sao shader with the ShaderMaterial.
   * @type {THREE.ShaderMaterial}
   */
  let _saoMaterial = new THREE.ShaderMaterial(_saoShader);
  _saoMaterial.uniforms.tDepth.value = _depthRenderTarget.texture;
  _saoMaterial.uniforms.tNormal.value = _normalRenderTarget.texture;
  _saoMaterial.needsUpdate = false;

  /**
   * The properties of the blur shaders.
   */
  let _blurShader = {
    defines: {
      KERNEL_RADIUS: _settings.getSetting('kernelRadius')
    },
    uniforms: {
      tAO: { type: 't', value: null },
      tDiffuse: { type: 't', value: null },
      tDepth: { type: 't', value: null },
      size: { type: 'v2', value: new THREE.Vector2(___settings.width, ___settings.height) },
      sampleWeights: { type: '1fv', value: _createSampleWeights(_settings.getSetting('standardDev'), _settings.getSetting('kernelRadius')) },
      orientation: { type: 'i', value: 0 },
      cameraNear: { type: 'f', value: 10 },
      cameraFar: { type: 'f', value: 1000 }
    },
    attributes: ['position', 'uv'],
    vertexShader: Shaders.basic_vert,
    fragmentShader: Shaders.blur_frag
  };

  /**
   * Creation of the horizontal blur shader with the ShaderMaterial.
   * @type {THREE.ShaderMaterial}
   */
  let _hBlur = new THREE.ShaderMaterial({
    uniforms: THREE.UniformsUtils.clone(_blurShader.uniforms),
    defines: _blurShader.defines,
    vertexShader: _blurShader.vertexShader,
    fragmentShader: _blurShader.fragmentShader
  });
  _hBlur.uniforms.tAO.value = _saoRenderTarget.texture;
  _hBlur.uniforms.tDepth.value = _depthRenderTarget.texture;
  _hBlur.uniforms.orientation.value = 0;

  /**
   * Creation of the vertical blur shader with the ShaderMaterial.
   * @type {THREE.ShaderMaterial}
   */
  let _vBlur = new THREE.ShaderMaterial({
    uniforms: THREE.UniformsUtils.clone(_blurShader.uniforms),
    defines: _blurShader.defines,
    vertexShader: _blurShader.vertexShader,
    fragmentShader: _blurShader.fragmentShader
  });
  _vBlur.uniforms.tAO.value = _blurIntermediateRenderTarget.texture;
  _vBlur.uniforms.tDepth.value = _depthRenderTarget.texture;
  _vBlur.uniforms.orientation.value = 1;

  /**
   * Creation of a full-screen quad scene to render textures to.
   */
  let _quadCamera = new THREE.OrthographicCamera(- 1, 1, 1, - 1, 0, 1);
  let _quadScene = new THREE.Scene();
  let _quad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), null);
  _quadScene.add(_quad);

  ////////////
  ////////////
  //
  // Methods
  //
  ////////////
  ////////////

  /**
   * Creation of the sample weights for the blur shaders
   *
   * @returns {Number[]} The sample weights
   */
  function _createSampleWeights (standardDev, kernelRadius) {
    let gaussian = function (x, stdDev) {
      return Math.exp(- (x * x) / (2.0 * (stdDev * stdDev))) / (Math.sqrt(2.0 * Math.PI) * stdDev);
    };
    let weights = [];
    for (let i = 0, i_max = kernelRadius; i <= i_max; i++) {
      weights.push(gaussian(i, standardDev));
    }
    return weights;
  }

  /**
   * Updates all camera related uniform values of the different shaders.
   *
   * @param {THREE.PerspectiveCamera} camera
   */
  function _updateCameraUniforms (camera) {
    _saoMaterial.uniforms.cameraNear.value = camera.near;
    _saoMaterial.uniforms.cameraFar.value = camera.far;
    _saoMaterial.uniforms.cameraProjectionMatrix.value = camera.projectionMatrix;
    _saoMaterial.uniforms.cameraInverseProjectionMatrix.value.getInverse(camera.projectionMatrix, true);
    _hBlur.uniforms.cameraNear.value = camera.near;
    _hBlur.uniforms.cameraFar.value = camera.far;
    _vBlur.uniforms.cameraNear.value = camera.near;
    _vBlur.uniforms.cameraFar.value = camera.far;
  }

  ////////////
  ////////////
  //
  // SaoPass API
  //
  ////////////
  ////////////

  /**
   * Renders the SaoPass.
   *
   * 4 Steps:
   * - Render the depth
   * - Render the normals
   * - Render the ambient occlusion
   * - Render the blurred ambient occlusion combined with the texture from the renderSource.texture to the renderTarget.texture
   *
   * @param {THREE.WebGLRenderer} renderer The renderer
   * @param {THREE.Scene} scene The scene
   * @param {THREE.PerspectiveCamera} camera The camera
   * @param {THREE.WebGLRenderTarget} renderSource The render target to which to apply the ambient occlusion
   * @param {THREE.WebGLRenderTarget} renderTarget The final result is stored in this render target
   */
  this.render = function (renderer, scene, camera, renderSource, renderTarget) {
    // Update all camera related uniform values
    _updateCameraUniforms(camera);

    // Traverse the scene and temporarily disable all object that do not have the ambient occlusion enabled.
    scene.traverse(function (object) {
      if (object.material) {
        if (!object.material.renderAO) {
          object.wasItVisible = object.visible;
          object.visible = false;
        }
      }
    });
    _vBlur.uniforms.tDiffuse.value = renderSource.texture;
    //_vBlur.needsUpdate = true;
    let sceneB = scene.background;
    scene.background = null;

    let originalClearColor = renderer.getClearColor().getHex();
    let originalClearAlpha = renderer.getClearAlpha();

    // Depth pass
    renderer.setClearColor(0xffffff);
    scene.overrideMaterial = _depthMaterial;
    renderer.render(scene, camera, _depthRenderTarget, true);

    // Normal pass
    renderer.setClearColor(0x7777ff);
    scene.overrideMaterial = _normalMaterial;
    renderer.render(scene, camera, _normalRenderTarget, true);

    // Ambient Occlusion pass
    renderer.setClearColor(originalClearColor, originalClearAlpha);
    _quad.material = _saoMaterial;
    renderer.render(_quadScene, _quadCamera, _saoRenderTarget, true);

    // Blur pass
    _quad.material = _hBlur;
    renderer.render(_quadScene, _quadCamera, _blurIntermediateRenderTarget, true);
    _quad.material = _vBlur;
    renderer.render(_quadScene, _quadCamera, renderTarget, true);

    scene.overrideMaterial = null;
    scene.background = sceneB;

    // Revert the changes that were done to objects that do not have ambient occlusion enabled.
    scene.traverse(function (object) {
      if (object.material) {
        if (!object.material.renderAO) {
          object.visible = object.wasItVisible;
        }
      }
    });
  };

  /**
   * Change the number of samples for the ambient occlusion.
   *
   * @param {Number} value The new sample count
   */
  var _samplesHook = function (value) {
    _saoMaterial.defines.samples =value;
    _saoMaterial.needsUpdate = true;
  };
  _settings.registerHook('samples', _samplesHook);

  /**
   * Change the intensity of the ambient occlusion.
   *
   * @param {Number} value The new intensity
   */
  var _intensityHook = function (value) {
    _saoMaterial.uniforms.intensity.value = value;
  };
  _settings.registerHook('intensity', _intensityHook);

  /**
   * CHange the kernel radius of the blur shaders.
   *
   * @param {Number} value The new kernel radius
   */
  var _kernelRadiusHook = function (value) {
    _hBlur.defines.KERNEL_RADIUS = value;
    _vBlur.defines.KERNEL_RADIUS = value;
    _hBlur.uniforms.sampleWeights.value = _createSampleWeights(_settings.getSetting('standardDev'), value);
    _vBlur.uniforms.sampleWeights.value = _createSampleWeights(_settings.getSetting('standardDev'), value);
    _hBlur.needsUpdate = true;
    _vBlur.needsUpdate = true;
  };
  _settings.registerHook('kernelRadius', _kernelRadiusHook);

  /**
   * Change the standard deviation of the blur shaders
   *
   * @param {Number} value The new standard deviation
   */
  var _standardDevHook = function (value) {
    _hBlur.uniforms.sampleWeights.value = _createSampleWeights(value, _settings.getSetting('kernelRadius'));
    _vBlur.uniforms.sampleWeights.value = _createSampleWeights(value, _settings.getSetting('kernelRadius'));
  };
  _settings.registerHook('standardDev', _standardDevHook);

  /**
   * Change the size of this pass.
   *
   * @param {Number} width The new width
   * @param {Number} height The new height
   */
  this.setSize = function (width, height) {
    _saoMaterial.uniforms.size.value.set(width, height);

    _hBlur.uniforms.size.value.set(width, height);
    _vBlur.uniforms.size.value.set(width, height);

    _saoRenderTarget.setSize(width, height);
    _depthRenderTarget.setSize(width, height);
    _normalRenderTarget.setSize(width, height);
    _blurIntermediateRenderTarget.setSize(width, height);
  };
};

module.exports = SaoPass;
