/**
 * @author Michael Oppitz
 */
let THREE = require('../../../externals/three');
let Shaders = require('../shaders/ShaderFile');

THREE.ShapeDiverGemMaterial = function (parameters) {
  THREE.MeshStandardMaterial.call(this);

  this.defines = {
    STANDARD: '',
    DEPTH: 5
  };

  this.uniforms = THREE.UniformsUtils.merge([
    THREE.UniformsLib.common,
    THREE.UniformsLib.envmap,
    THREE.UniformsLib.aomap,
    THREE.UniformsLib.lightmap,
    THREE.UniformsLib.bumpmap,
    THREE.UniformsLib.normalmap,
    THREE.UniformsLib.displacementmap,
    THREE.UniformsLib.roughnessmap,
    THREE.UniformsLib.metalnessmap,
    THREE.UniformsLib.lights,
    {
      emissive: { value: new THREE.Color(0x000000) },
      roughness: { value: 0.0 },
      metalness: { value: 1.0 },
      envMapIntensity: { value: 1 }, // temporary
    },
    {
      refractionIndex: { value: 2.4 },
      impurityMap: { value: null },
      impurityScale: { value: 1.0 },
      colorTransferBegin: { value: new THREE.Color(0xffffff) },
      colorTransferEnd: { value: new THREE.Color(0xffffff) },
      center: { value: new THREE.Vector3(0,0,0) },
      radius: { value: 1.0 },
      sphericalNormalMap: { value: null },
      gamma: { value: 1.0 },
      contrast: { value: 1.0 },
      brightness: { value: 0.0 },
      dispersion: { value: 0.0 },
      tracingOpacity: { value: 0.0 },
      inverseModelMatrix: {value: new THREE.Matrix4()},
      inverseTransposeModelMatrix: {value: new THREE.Matrix4()},
    }
  ]);

  if(parameters) {

    this.customEnvMap = parameters.customEnvMap;

    delete parameters.customEnvMap;
    delete parameters.materialType;
    delete parameters.environmentMap;

    this.depth = parameters.depth;
    this.defines.DEPTH = this.depth;
    if(parameters.dispersion != 0.0)
      this.defines.DISPERSION = '';

    this.roughness = 0.0;
    this.metalness = 1.0;
    this.transparent = true;

    if(parameters.impurityMap)
      this.defines.USE_IMPURITY_MAP = '';

    this.uniforms.inverseModelMatrix.value = parameters.inverseModelMatrix;
    this.inverseModelMatrix = parameters.inverseModelMatrix;
    this.uniforms.inverseTransposeModelMatrix.value = parameters.inverseTransposeModelMatrix;
    this.inverseTransposeModelMatrix = parameters.inverseTransposeModelMatrix;
    this.uniforms.refractionIndex.value = parameters.refractionIndex;
    this.refractionIndex = parameters.refractionIndex;
    this.uniforms.dispersion.value = parameters.dispersion;
    this.dispersion = parameters.dispersion;
    this.uniforms.center.value = parameters.center;
    this.center = parameters.center;
    this.uniforms.radius.value = parameters.radius;
    this.radius = parameters.radius;
    this.uniforms.sphericalNormalMap.value = parameters.sphericalNormalMap;
    this.sphericalNormalMap = parameters.sphericalNormalMap;
    
    this.uniforms.impurityMap.value = parameters.impurityMap;
    this.impurityMap = parameters.impurityMap;
    this.uniforms.impurityScale.value = parameters.impurityScale;
    this.impurityScale = parameters.impurityScale;
    this.uniforms.colorTransferBegin.value = parameters.colorTransferBegin;
    this.colorTransferBegin = parameters.colorTransferBegin;
    this.uniforms.colorTransferEnd.value = parameters.colorTransferEnd;
    this.colorTransferEnd = parameters.colorTransferEnd;
    this.uniforms.gamma.value = parameters.gamma;
    this.gamma = parameters.gamma;
    this.uniforms.contrast.value = parameters.contrast;
    this.contrast = parameters.contrast;
    this.uniforms.brightness.value = parameters.brightness;
    this.brightness = parameters.brightness;
    this.uniforms.tracingOpacity.value = parameters.tracingOpacity;
    this.tracingOpacity = parameters.tracingOpacity;
  }

  this.vertexShader = Shaders.gem_vert;
  this.fragmentShader = Shaders.gem_frag;
  this.type = 'ShapeDiverGemMaterial';

  delete parameters.resolution;
  delete parameters.line;
  delete parameters._json;

  this.needsUpdate = false;
  this.setValues(parameters);
};

THREE.ShapeDiverGemMaterial.prototype = Object.create(THREE.MeshStandardMaterial.prototype);
THREE.ShapeDiverGemMaterial.prototype.constructor = THREE.ShapeDiverGemMaterial;
THREE.ShapeDiverGemMaterial.prototype.isMeshStandardMaterial = true;

THREE.ShapeDiverGemMaterial.prototype.copy = function (source) {
  THREE.MeshStandardMaterial.prototype.copy.call(this, source);
  this.defines = source.defines;
  this.uniforms = THREE.UniformsUtils.clone(source.uniforms);
  this.type = source.type;
  return this;
};

THREE.ShapeDiverGemMaterial.prototype.deepCopy = function (source) {
  THREE.MeshStandardMaterial.prototype.copy.call(this, source);
  this.defines = Object.assign({}, source.defines);
  this.uniforms = THREE.UniformsUtils.clone(source.uniforms);
  this.type = source.type;
  return this;
};
