/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiExportImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer Export API V2.1
 *
 * @module ExportApi
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
* Imported messaging constant definitions
*/
var messagingConstants = require('../../../shared/constants/MessagingConstants');

/**
* Imported global plugin constants
*/
var pluginConstantsGlobal = require('../../../shared/constants/PluginConstantsGlobal');

/**
* Import GlobalUtils
*/
var GlobalUtils = require('../../../shared/util/GlobalUtils');

/**
* ApiInterfaceV2
*/
var ApiInterfaceV2 = new (require('../ApiInterfaceV2.1'))();

/**
* APIResponse factory
*/
const APIResponse = require('../ApiResponse');

////////////
////////////
//
// Export Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Export Interface
 * @class
 * @implements {module:ApiExportInterface~ApiExportInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.exportHandler - Reference to the export handler
 */
var ExportApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.RESULT = ApiInterfaceV2.exports.RESULT;
  this.EVENTTYPE = ApiInterfaceV2.exports.EVENTTYPE;

  // shortcuts to handlers
  var _exportHandler = ___refs.exportHandler;
  var _parameterHandler = ___refs.parameterHandler;

  /** @inheritdoc */
  this.get = function () {
    return APIResponse(null, _exportHandler.getExportDefinitions());
  };

  /** check for a ExportRequestObject */
  var _isExportRequestObject = function (o) {
    if (!o || typeof o !== 'object')
      return false;
    // at least one of id, idOrName, or name must exist
    if (!GlobalUtils.typeCheck(o.id, 'string') && !GlobalUtils.typeCheck(o.idOrName, 'string') && !GlobalUtils.typeCheck(o.name, 'string'))
      return false;
    // if plugin exists, it must be a string
    if (o.plugin && typeof o.plugin !== 'string')
      return false;
    // got it
    return true;
  };

  /** @inheritdoc */
  this.requestAsync = function (_ero, payload) {
    var scope = 'ApiImplementationV2.ExportApi.requestAsync';

    // parameter sanity check
    if (!_isExportRequestObject(_ero)) {
      return Promise.resolve(APIResponse('Invalid input, expecting an ExportRequestObject.', null, payload));
    }

    // find unique export matching _ero
    let ero = _exportHandler.getUniqueExportByRequestObject(_ero);
    if (ero === undefined) {
      return Promise.resolve(APIResponse('Invalid input, ExportRequestObject did not match a unique export definition.', null, payload));
    }

    // in case _ero contains parameter values, pass them on
    if (_ero.parameters) {
      ero.parameters = _ero.parameters;
    }

    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    if (_ero.silent) token.attributes = { silent: true };

    return new Promise(function (resolve) {

      // define function for export
      let f_export = function () {

        // create a process callback and register it
        let processCallback = function (t, v) {
          if (v.hasOwnProperty('parts')) {
            for (let p of v.parts) {
              if (p.type == messagingConstants.messageDataTypes.EXPORT_RESULT) {
                // copy p.data to ero and return
                GlobalUtils.defaults(ero, p.data, true);
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(null, ero, payload));
                return;
              }
              if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(p.data, null, payload));
                return;
              }
              if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(p.data, null, payload));
                return;
              }
            }
          }
        };
        let subToken = _api.setProcessCallback(token.id, processCallback);

        // call _exportHandler
        var r = _exportHandler.requestExport(ero, token);
        ero.resultcode = r;

        // in case of error in _exportHandler, report it and immediately stop subscription
        if (r !== pluginConstantsGlobal.requestExportResults.CACHE &&
          r !== pluginConstantsGlobal.requestExportResults.LOAD) {
          _api.error(scope, '_exportHandler.requestExport returned error', r);
          _api.clearProcessCallback(subToken);
          resolve(APIResponse({ m: 'Failed to request export', d: r }, ero, payload));
          return;
        }

        // wait for process message (see above)
      };

      // were we given any parameter key value pairs?
      if (!ero.parameters) {
        f_export();
        return;
      }

      // check for parameters which require upload 
      const paramArr = [];
      const params = ero.parameters;
      for (let k in params) {
        paramArr.push({id: k, value: params[k]});
      }
      let requiresPriorUpload = _parameterHandler.updateRequiresPriorUpload(paramArr);
      if (requiresPriorUpload === undefined) {
        resolve(APIResponse('Parameter not found', null, payload));
      } else if (requiresPriorUpload === false) {
        f_export();
      } else {
        // in case there are, call the upload
        _parameterHandler.priorUpload(paramArr)
          .then(
            function (r) {
              if (r.err) {
                _api.error(scope, '_parameterHandler.priorUpload returned error', r);
                resolve(APIResponse({ m: 'Failed to upload blobs', d: r }, null, payload));
              } else {
                // values of file parameters have been updated by priorUpload
                for (let p of paramArr) {
                  params[p.id] = p.value; 
                }
                f_export();
              }
            },
            function (error) {
              _api.error(scope, '_parameterHandler.priorUpload failed', error);
              resolve(APIResponse({ m: 'Failed to upload blobs', d: error }, null, payload));
            }
          );
      }

    });
  };

  /** @inheritdoc */
  this.updateProperties = function (definitions) {
    return APIResponse(null, _exportHandler.updateMultipleExports(definitions));
  };

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.EXPORT + '.' + type;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      event.export = {};
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.EXPORT_DEFINITION] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.EXPORT_RESULT] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.EXPORT_STATUS] = null; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            event.export[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };

};

module.exports = ExportApi;