/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *MessagePrototype.js*
 *
 * ### Content
 *   * prototype for a message
 *
 * @module MessagePrototype
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Import GlobalUtils
 */
var GlobalUtils = require('../util/GlobalUtils');

/**
 * Constructor of the message prototype
 * @class MessagePrototype
 * @author Alex Schiftner <alex@shapediver.com>
 * @param {module:MessagingConstants~MessageDataType} [type] - data type to set for first data part of message
 * @param {Object} [data] - data object to set for first data part of message
 * @param {String|module:MessagingConstants~MessageToken} [token] - either an existing token to be passed on, or a string defining the id of a new token, or undefined in which case a new token id will be created
 */
var MessagePrototype = function(___type, ___data, ___token) {

  var that = this;

  /**
   * Origin of the message
   * @type {module:MessagingConstants~MessageOrigin}
   */
  this.origin = {};

  /**
   * Message data parts
   * @type {module:MessagingConstants~MessageDataPart[]}
   */
  this.parts = [];

  /**
   * Add a message data part
   * @param {module:MessagingConstants~MessageDataType} type - data type to set for further data part of message
   * @param {Object} data - data object to set for further data part of message
   */
  this.addPart = function(type, data) { //#SS-975 move this function to prototype
    if ( type !== undefined ) {
      that.parts.push( { type: type, data: data } );
    }
    //else {
    //  console.warn('CAUTION: data part with undefined type, data='+JSON.stringify(data));
    //}
  };

  // add data given as constructor parameters
  this.addPart(___type, ___data);

  /**
   * Get potentially multiple message data parts by type
   * @param {module:MessagingConstants~MessageDataType} type - data type to look for
   * @return {module:MessagingConstants~MessageDataPart} data parts if found, may be an empty array
   */
  this.getPartsByType = function(type) { //#SS-975 move this function to prototype
    let parts = that.parts.filter( (p) => {return p.type === type;} );
    return parts;
  };

  /**
   * Get unique message data part by type (if exists and unique)
   * @param {module:MessagingConstants~MessageDataType} type - data type to look for
   * @return {module:MessagingConstants~MessageDataPart} data part if found, undefined otherwise
   */
  this.getUniquePartByType = function(type) { //#SS-975 move this function to prototype
    let parts = that.getPartsByType(type);
    if ( parts.length === 1 ) {
      return parts[0];
    }
  };

  /**
   * Token attached to message - to be passed on by any operation in response to this message
   * @type {module:MessagingConstants~MessageToken}
   */
  this.token = {}; //#SS-975 move all of the initialisation code to a function of the prototype
  if ( typeof ___token === 'string' && ___token.length > 0 ) {
    this.token = {id: ___token};
  }
  else if ( ___token !== null && typeof ___token === 'object' && typeof ___token.id === 'string' && ___token.id.length > 0 ) {
    this.token = ___token;
  }
  else {
    this.token.id = GlobalUtils.createRandomId();
  }
  if ( this.token.serial === undefined || typeof this.token.serial !== 'number' ) {
    this.token.serial = Date.now();
  }

  /**
   * Creation date attached to message - number of milliseconds until 1970
   * @type {Number}
   */
  this.date = Date.now();

  return this;
};

// export the constructor
module.exports = MessagePrototype;
