/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *PluginPrototype.js*
 *
 * ### Content
 *   * plugin prototype class to be specialised further
 *   * provides an implementation of {@link PluginInterface#runtimeId}
 *   * provides an implementation of {@link PluginInterface#load}
 *   * mixes in functionality for settings, logging, and messaging
 *
 * @module PluginPrototype
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Import plugin constant definitions
 */
var pluginConstants = require('./PluginConstants');

/**
 * Import global utils
 */
var GlobalUtils = require('../shared/util/GlobalUtils');

/**
 * Import global mixin
 */
var GlobalMixin = require('../shared/mixins/GlobalMixin');

/**
 * Import settings mixin
 */
var SettingsMixin = require('../shared/mixins/SettingsMixin');

/**
 * Import logging mixin
 */
var LoggingMixin = require('../shared/mixins/LoggingMixin');

/**
 * Import messaging mixin
 */
var MessagingMixin = require('../shared/mixins/MessagingMixin');

/**
 * Message prototype
 */
var MessagePrototype = require('../shared/messages/MessagePrototype');

/**
 * Import Texture Loader
 */
var TextureLoader = require('../shared/singletons/TextureLoader');

/**
 * THREE.JS
 */
var THREE = require('../externals/three.js');

/**
 Constructor of the plugin prototype
 @class PluginPrototype
 @author Alex Schiftner <alex@shapediver.com>

 @implements {@link module:PluginInterface~PluginInterface}

 @mixes module:GlobalMixin~GlobalMixin
 @mixes module:SettingsMixin~SettingsMixin
 @mixes module:LoggingMixin~LoggingMixin
 @mixes module:MessagingMixin~MessagingMixin

 @param {Object} [settings] - Initial settings to be used
 @param {Number} [settings.loggingLevel] - Initial logging level
 @param {String} [settings.runtimeId] - Optional runtime id, otherwise one will be created automatically, must be unique, used for identifying parameters with the same id across multiple plugins
 */
var PluginPrototype = function(___settings) {

  var that = this;

  /**
   * Plugin constants, Global utilities, Message prototype, etc
   * We add them such that they become available to plugins deriving from this prototype.
   */
  this.pluginConstants = pluginConstants;
  this.GlobalUtils = GlobalUtils;
  this.MessagePrototype = MessagePrototype;
  that.TextureLoader = TextureLoader;
  that.THREE = THREE;

  ////////////
  ////////////
  //
  // Global prototype
  //
  ////////////
  ////////////

  GlobalMixin.call(this);

  ////////////
  ////////////
  //
  // Settings
  //
  ////////////
  ////////////

  // create a runtime id if none was given
  if ( ___settings.runtimeId === undefined || typeof ___settings.runtimeId !== 'string' )
    ___settings.runtimeId = GlobalUtils.createRandomId();

  SettingsMixin.call(this, ___settings, that.pluginConstants.defaultSettings);

  // prevent updating of runtime id
  this.registerHook('runtimeId',() => false);

  ////////////
  ////////////
  //
  // Logging
  //
  ////////////
  ////////////

  // register standard logging functions for debugging,
  // will be replaced by ViewerApp pub/sub logging upon initialization of plugin
  LoggingMixin.call(this);

  ////////////
  ////////////
  //
  // General messaging via pub/sub
  //
  ////////////
  ////////////

  // import from global prototype
  // will be replaced by ViewerApp pub/sub messaging upon initialization of plugin
  MessagingMixin.call(this);

  ////////////
  ////////////
  //
  // Plugin API
  //
  ////////////
  ////////////

  /**
   * Plugin runtime id
   * @public
   * @return {String} unique runtime id of plugin instance
   */
  this.getRuntimeId = function() {
    return that.getSetting('runtimeId');
  };

  /**
   * Plugin load function
   * @public
   * @param {Object} [settings] - optional settings to override default settings
   * @return {Boolean} true in case of success
   */
  this.load = function(settings) {
    // update settings
    that.updateSettings( settings );
    // loading succeeded
    return true;
  };

  ////////////
  ////////////
  //
  // Plugin status
  //
  ////////////
  ////////////

  // define empty members for documentation

  /**
   * Get plugin status
   * @public
   * @return {PluginStatus} current plugin status
   */
  this.getStatus = function() {};

  /**
   * Set plugin status
   * @public
   * @param {PluginStatus} status - new status to set
   */
  this.setStatus = function() {};

  // inject this.status and this.setStatus
  // #SS-764: plugin status implementation should be changed to use settings
  (function() {
    // define plugin status
    var _status = that.pluginConstants.pluginStatuses.READY;

    // return accessor functions for status
    that.getStatus = function() { return GlobalUtils.deepCopy(_status); };
    that.setStatus = function(s) {
      // send message on change of status
      if ( _status !== s ) {
        let status = that.pluginConstants.messageTopics.PLUGIN_FAILED;
        if (s === that.pluginConstants.pluginStatuses.FAILED) {
          status = that.pluginConstants.messageTopics.PLUGIN_FAILED;
        } else if (s === that.pluginConstants.pluginStatuses.LOADED) {
          status = that.pluginConstants.messageTopics.PLUGIN_LOADED;
        } else if (s === that.pluginConstants.pluginStatuses.ACTIVE) {
          status = that.pluginConstants.messageTopics.PLUGIN_ACTIVE;
        } else if (s === that.pluginConstants.pluginStatuses.UNLOADED) {
          status = that.pluginConstants.messageTopics.PLUGIN_UNLOADED;
        }
        let m = new MessagePrototype(that.pluginConstants.messageDataTypes.PLUGIN_RUNTIME_ID, that.getRuntimeId());
        that.message(status, m);
        _status = s;
      }
    };
  }).call(this);

  /**
   * Get plugin status description
   *
   * @public
   * @return {module:PluginConstantsGlobal~PluginStatusDescription} Plugin status descriptions
   */
  this.getStatusDescription = function() {
    return {
      id: that.getRuntimeId(),
      status: that.getStatus(),
      name: that.getName(),
      shortName: that.getShortName()
    };
  };

  return this;
};

// here we could define public functions
//PluginPrototype.prototype.

// export the constructor
module.exports = PluginPrototype;
