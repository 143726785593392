/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerAppMessagingPartial.js*
 *
 * ### Content
 *   * Messaging functionality of ViewerApp
 *
 * @module ViewerAppMessagingPartial
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Imported global utilities
 */
const GLOBALUTILS = require('../../shared/util/GlobalUtils');

/**
 * Imported messaging constants
 */
const MESSAGINGCONSTANTS = require('../../shared/constants/MessagingConstants');

const PUBSUB = require('../../shared/pubsub/PubSub');

/**
  * Constructor of the ViewerAppMessagingPartial mixin
  * @mixin ViewerAppMessagingPartial
  * @author Alex Schiftner <alex@shapediver.com>
  */
var ViewerAppMessagingPartial = function() {

  var that = this;

  // pub/sub communication for general messages
  var _pubSubMessages = new PUBSUB();
  //_pubSubMessages.immediateExceptions = true

  /**
    * Replace message function defined by messaging prototype, and send messages to pub/sub
    * @public
    * @param {module:MessagingConstants~MessageTopic} topic - topic to be used, static parts of topics shall be assembled from {@link module:MessagingConstants~MessageTopic}
    * @param {module:MessagePrototype~MessagePrototype} msg - message object
    */
  this.message = function(topic, msg) {
    // in case this is a process message, and the message contains a token id, attach the token to the topic
    let _topic;
    if ( topic === MESSAGINGCONSTANTS.messageTopics.PROCESS && msg.token && msg.token.id ) {
      _topic = topic + '.' + msg.token.id;
    } else {
      _topic = topic;
    }
    // send to pub/sub
    that.messageToPubSub( _topic, msg, _pubSubMessages, {} );
    // send to log, uses messageLoggingLevel
    that.messageToLog( _topic, msg );
  };

  /**
    * Hook into the message stream, optionally limited to certain topics
    * @public
    * @param {module:MessagingConstants~MessageTopic|module:MessagingConstants~MessageTopic[]} topic - single or several topic to subscribe to
    * @param {Function} callback - will be invoked with (topic, payload)
    * @return {Object[]} Array of subscription tokens, to be used for unsubscribing
    */
  this.subscribeToMessageStream = function(topic, callback) {
    // subscribe to all given topics
    if ( !Array.isArray(topic) )
      topic = [topic];
    // create the subscriptions
    let tokens = [];
    topic.forEach( function(t) {
      tokens.push( _pubSubMessages.subscribe(t, callback) );
    });
    return tokens;
  };

  /**
    * Unsubscribe from message stream using tokens of previously generated subscriptions
    * @public
    * @param {Object[]} tokens - subscription tokens to unsubscribe from
    * @return {Boolean} true on success, false on error
    */
  this.unsubscribeFromMessageStream = function(tokens) {
    // sanity check
    if ( !Array.isArray(tokens) )
      tokens = [tokens];
    // unsubscribe
    let s = true;
    tokens.forEach( function(t) {
      if (!_pubSubMessages.unsubscribe(t)) s = false;
    });
    return s;
  };


  /**
   * Setup dispatching, i.e. message subscriptions and corresponding callbacks.
   * @public
   * @param {Object} conf - dispatching configuration as created by {@link module:DispatchingConfig~DispatchingConfig}
   * @return {Object[]} Array of subscription tokens, to be used for unsubscribing
   */
  this.setupDispatching = function(conf) {
    let scope = 'ViewerAppMessagingPartial.setupDispatching';
    let tokens = [];
    // loop over topics
    Object.keys(conf).forEach( function(topic) {
      // get part listener config for current topic
      let partListeners = conf[topic].partListeners;
      // subscribe to message stream using a locally defined callback
      let t = that.subscribeToMessageStream(topic, function(topic, msg) {
        // this callback will be invoked by pubsub once a message arrives
        // msg sanity check
        if ( !Array.isArray(msg.parts) ) {
          msg.parts = [];
          that.warn(scope, 'Message without data parts: ' + JSON.stringify(msg, null, 0));
        }
        // loop through message parts and find matching callbacks
        msg.parts.forEach( function(part) {
          // check which listeners correspond to this type of part
          partListeners.forEach( function(partConf) {
            if (part.type === partConf.type) {
              // is there a callback for this listener? (we might have to resolve it ourselves)
              let cb = partConf.cb;
              // check if we have to resolve the callback ourselves
              if ( typeof cb === 'string' && cb.length > 0 ) {
                cb = GLOBALUTILS.getAt(that, cb.split('.') );
              }
              if ( typeof cb === 'function' ) {
                // collect parameters for the callback
                let params = [];
                if ( !Array.isArray(partConf.params) ) {
                  params = [msg.token, part.data, part.type, msg.origin];
                } else {
                  params = partConf.params.map( function(k) {
                    if ( k === 'type')
                      return part.type;
                    else if ( k === 'data')
                      return part.data;
                    else if ( k === 'token')
                      return msg.token;
                    else if ( k === 'origin')
                      return msg.origin;
                    else if ( k === 'topic')
                      return topic;
                    else if ( k === 'plugin')
                      return msg.origin.plugin;
                  });
                }
                // invoke the callback
                let ret = Promise.resolve(cb.apply(that, params));
                ret.then(function(v) {
                  // log warning in case callback returned boolean false
                  if ( v === false ) {
                    that.debug(scope, 'Message callback returned false', partConf, params);
                  }
                });
                ret.catch(function(e) {
                  // log error
                  that.error(scope, 'Error in message callback: ' + e.message, e, partConf, params);
                });
              }
            }
          }); // - partListeners.forEach
        }); // - msg.parts.forEach
      }); // - subscribeToMessageStream
      // add tokens to global list
      tokens = tokens.concat(t);
    });
    return tokens;
  };

  /**
  * subscribe to and collect STATUS_FAILED messages, which may occur very early
  * we do this in order to allow these messages to be read by api instances after initialisation
  */
  let _failedStatusMessages = [];
  this.subscribeToMessageStream( MESSAGINGCONSTANTS.messageTopics.STATUS_FAILED, function(topic, msg) {
    let part = msg.getUniquePartByType(MESSAGINGCONSTANTS.messageDataTypes.STATUS_MESSAGE);
    if (part) {
      if (part.data) {
        _failedStatusMessages.push(part.data);
      }
    }
  });

  /**
  * get data of STATUS_FAILED messages which have been recorded
  */
  this.getFailedStatusMessages = function() {
    return _failedStatusMessages;
  };

};

module.exports = ViewerAppMessagingPartial;
