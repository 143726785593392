/**
 * @file Creates a shared interface for all CameraApis.
 *       The CameraApiInterface must be implemented by all CameraApis.
 *       All described methods must be implemented.
 *
 * @module CameraApiInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the CameraApi.
 *
 * @ignore//@interface CameraApiInterface
 */
class CameraApiInterface {
  /**
   * The constructor for the {@link module:CameraApiInterface~CameraApiInterface}.
   * @constructs module:CameraApiInterface~CameraApiInterface
   */
  constructor() {
    /**
      * Enum for camera types.
      * @readonly
      * @enum {module:CameraApi~CameraApi#CameraType}
      */
    this.TYPE = {
      /** perspective camera */
      PERSPECTIVE: 0,
      /** orthographic camera, top view */
      TOP: 1,
      /** orthographic camera, bottom view */
      BOTTOM: 2,
      /** orthographic camera, right view */
      RIGHT: 3,
      /** orthographic camera, left view */
      LEFT: 4,
      /** orthographic camera, back view */
      BACK: 5,
      /** orthographic camera, front view */
      FRONT: 6,
      /** AR camera */
      AR: 7
    };

    /**
     * Enum for control types.
     * @readonly
     * @enum {module:CameraApi~CameraApi#ControlType}
     */
    this.CONTROLTYPE = {
      /** the orbit controls (default for perspective camera) */
      ORBIT: 0,
      /** the fps controls */
      FPS: 1,
      /** the orthographic controls (default for orthograhpic camera) */
      ORTHOGRAPHIC: 2,
    };

    /**
     * Enum for supported camera event types.
     * @readonly
     * @enum {module:CameraApi~CameraApi#CameraEventType}
     */
    this.EVENTTYPE = {
      /**  a camera movement has started */
      CAMERA_START: 'start',
      /**  a camera movement has been continued */
      CAMERA_MOVE: 'move',
      /** a camera movement has ended */
      CAMERA_END: 'end',
    };
  }

/**
  * Camera event type, see {@link module:CameraApi~CameraApi#EVENTTYPE} for values
  * @typedef {String} module:CameraApi~CameraApi#CameraEventType
  * @see {@link module:CameraApi~CameraApi#addEventListener addEventListener}
  */

  /**
   * The camera definition including the camera's position and target as {@link module:ApiInterfaceV2~ApiInterfaceV2#Point3d}.
   *
   * @typedef {Object} module:CameraApiInterface~CameraApiInterface#CameraDefinition
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} position The position of the camera in the scene
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} target The position of the target in the scene
   */

  /**
    * Definition of a transition function for camera movements
    *
    * @typedef {Object} module:CameraApiInterface~CameraApiInterface#TransitionParameters
    * @property {Boolean} [default=false] - If true store final camera position and target as default.
    * @property {Number} [duration=1000] - duration of the transition in milliseconds
    * @property {String|Function} [easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
    * @property {String} [interpolation='CatmullRom'] - In case a string S is provided, the corresponding interpolation function TWEEN.Interpolation[S] will be used if it exists. Tween supports Linear, Bezier, and CatmullRom.
    * @property {String} [coordinates='cylindrical'] - Defines coordinate system to use for animated camera paths. One of 'spherical' or 'cylindrical'.
    */

  /**
   * Returns the position and target of the camera as a {@link module:CameraApiInterface~CameraApiInterface#CameraDefinition}.
   * If no target is available for this type of camera, the property target is undefined.
   *
   * @abstract
   * @returns {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a {@link module:CameraApiInterface~CameraApiInterface#CameraDefinition CameraDefinition}.
   */
  get() { }

  /**
   * Update camera
   *
   * If target and/or position are provided, the camera will be moved to its new orientation
   *
   * @abstract
   * @param {module:CameraApiInterface~CameraApiInterface#CameraDefinition} camera - The new camera properties
   * @param {module:CameraApiInterface~CameraApiInterface#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:CameraApiInterface~CameraApiInterface#CameraDefinition CameraDefinition} according to the new properties.
   */
  updateAsync(camera, transition) { }

  /**
   * Animate camera along a path. The camera starts at the first position of the path, so if the camera should start from the current position, add it to the start of the path.
   *
   * @abstract
   * @param {module:CameraApiInterface~CameraApiInterface#CameraDefinition[]} path - Array of camera definition objects defining a camera path
   * @param {module:CameraApiInterface~CameraApiInterface#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:CameraApiInterface~CameraApiInterface#CameraDefinition CameraDefinition} according to the camera's final position.
   */
  animateAsync(path, transition) { }

  /**
   * Zoom to show some or all objects in the scene
   *
   * @abstract
   * @param {module:ApiInterfaceV2~ScenePathType[]|THREE.Box3} [scenePathsOrBB] - The paths of the scene which the camera should capture or a 3D box which should be encapsulated afterwards. If none are provided, the camera will zoom to the scene extents.
   * @param {module:CameraApiInterface~CameraApiInterface#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:CameraApiInterface~CameraApiInterface#CameraDefinition CameraDefinition} of the camera's new orientation.
   */
  zoomAsync(scenePathsOrBB, transition) { }

  /**
   * Reset camera to its currently configured default configuration
   *
   * @abstract
   * @param {module:CameraApiInterface~CameraApiInterface#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a {@link module:CameraApiInterface~CameraApiInterface#CameraDefinition CameraDefinition} of the camera's default definition.
   */
  resetAsync(transition) { }

  /**
   * Update camera based on raw camera view and projection matrices.
   *
   * When providing raw updates to the camera matrices, the camera controls should typically be disabled using setting scene.camera.enableCameraControls
   *
   * @abstract
   * @param {THREE.Matrix4} viewMatrix camera view matrix, transforming world coordinates to camera coordinates
   * @param {THREE.Matrix4} projectionMatrix camera {@link https://threejs.org/docs/#api/en/cameras/Camera.projectionMatrix projection matrix}
   */
  updateMatrices() { }

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:CameraApi~CameraApi#CameraEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:CameraApi~CameraApi#removeEventListener removeEventListener}.
   */
  addEventListener(type, callback) { }

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  removeEventListener() { }

}

module.exports = CameraApiInterface;
