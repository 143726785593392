/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiResponse.js*
 *
 * ### Content
 *   * Factory for APIResponse objects, used by API V2
 *
 * @module ApiResponse
 * @author Alexander Schiftner
 */


/**
  * Import GlobalUtils
  */
const GLOBALUTILS = require('../../shared/util/GlobalUtils');

// /**
//  * APIResponse factory
//  */
// module.exports = function (err, data, payload) {
//   let _o = new Object();
//   // did we get an error
//   if (err !== undefined && err !== null) {
//     if (GLOBALUTILS.typeCheck(err, 'string')) // string error message
//       _o.err = new Error(err);
//     else if (err instanceof Error) // Error object
//       _o.err = err;
//     else { // special case: string error message and data related to error
//       if (err.hasOwnProperty('m')) {
//         _o.err = new Error(err.m);
//         if (err.hasOwnProperty('d'))
//           _o.err.data = new Error(err.d);
//       }
//       else { // data related to error
//         _o.err = new Error();
//         _o.err.data = err;
//       }
//     }
//   }
//   // pass through data and payload
//   if (data !== undefined && data !== null) _o.data = data;
//   if (payload !== undefined && payload !== null) _o.payload = payload;
//   return _o;
// };

class ApiResponse {
  constructor(err, data, payload) {
    this.err = null;
    this.data = null;
    this.payload = null;

    // did we get an error
    if (!Array.isArray(err))
      err = [err];

    for(let i = 0; i < err.length; i++) {
      let e = err[i];

      if (e !== undefined && e !== null) {
        if (GLOBALUTILS.typeCheck(e, 'string')) // string error message
          err[i] = new Error(e);
        else if (e instanceof Error) // Error object
          err[i] = e;
        else { // special case: string error message and data related to error
          if (e.hasOwnProperty('m')) {
            err[i] = new Error(e.m);
            if (e.hasOwnProperty('d'))
              err[i].data = new Error(e.d);
          }
          else { // data related to error
            err[i] = new Error();
            err[i].data = e;
          }
        }
      } else if (e !== null) { // if udnefined set it to null
        err[i] = null;
      }
    }

    // if there is only one value, set it directly
    if (err.length === 1 && err[0] !== undefined && err[0] !== null){ 
      this.err = err[0];
    } else if(err.length > 1) {
      this.err = err;
    }

    // pass through data and payload
    if (data !== undefined && data !== null) this.data = data;
    if (payload !== undefined && payload !== null) this.payload = payload;
  }

  
}


module.exports = function(err, data, payload) {
  return new ApiResponse(err, data, payload);
};
