
let ViewportManagerThreeDManager = function(viewportManager, _apis) {
  viewportManager.threeDManager = {};
  viewportManager.threeDManager.adjustScene = function () {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.adjustScene();
  };
  
  viewportManager.threeDManager.fadeIn = function (path, duration) {
    let promises = [];
    for (let i = 0, len = _apis.length; i < len; i++)
      promises.push(_apis[i].threeDManager.fadeIn(path, duration));
    return Promise.all(promises);
  };
  
  viewportManager.threeDManager.fadeOut = function (path, duration) {
    let promises = [];
    for (let i = 0, len = _apis.length; i < len; i++)
      promises.push(_apis[i].threeDManager.fadeOut(path, duration));
    return Promise.all(promises);
  };
  
  viewportManager.threeDManager.updateInteractions = function (path, options) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.updateInteractions(path, options);
  };
  
  viewportManager.threeDManager.removeFromInteractions = function (path) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.removeFromInteractions(path);
  };
  
  viewportManager.threeDManager.addMesh = function (mesh, materials, properties) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.addMesh(mesh, materials[i], properties);
  };
  
  viewportManager.threeDManager.removeMesh = function (mesh) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.removeMesh(mesh);
  
    mesh.geometry.dispose();
    mesh = undefined;
  };
  
  viewportManager.threeDManager.addAnchor = function (object, properties) {
    let viewports = [];
    if (properties.viewports && properties.viewports.length !== 0)
      viewports = properties.viewports;
  
    for (let i = 0, len = _apis.length; i < len; i++)
      if (viewports.includes(_apis[i].getViewportRuntimeId()) || viewports.length === 0)
        _apis[i].threeDManager.addAnchor(object, properties);
  };
  
  viewportManager.threeDManager.removeAnchor = function (object) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.removeAnchor(object);
  };
  
  
  viewportManager.threeDManager.renderingHandler = {};
  
  viewportManager.threeDManager.renderingHandler.render = function () {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.render();
  };
  
  viewportManager.threeDManager.renderingHandler.setBlur = function (blur, options) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.setBlur(blur, options);
  };
  
  viewportManager.threeDManager.renderingHandler.registerForContinuousRendering = function (id, rendering) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.registerForContinuousRendering(id, rendering);
  };  
  
  viewportManager.threeDManager.renderingHandler.registerForContinuousRenderingSingle = function (id, rendering) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.registerForContinuousRenderingSingle(id, rendering);
  };
  
  viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering = function (id) {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.unregisterForContinuousRendering(id);
  };
  
  viewportManager.threeDManager.renderingHandler.containsContinuousRendering = function (id) {
    let response = [];
    for (let i = 0, len = _apis.length; i < len; i++)
      response.push(_apis[i].threeDManager.renderingHandler.containsContinuousRendering(id));
    return response.length === 1 ? response[0] : response;
  };
  
  viewportManager.threeDManager.renderingHandler.updateShadowMap = function () {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.updateShadowMap();
  };

  viewportManager.threeDManager.renderingHandler.keepUpdatingShadowMap = function () {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.keepUpdatingShadowMap();
  };

  viewportManager.threeDManager.renderingHandler.stopUpdatingShadowMap = function () {
    for (let i = 0, len = _apis.length; i < len; i++)
      _apis[i].threeDManager.renderingHandler.stopUpdatingShadowMap();
  };
  
  
  
  
  viewportManager.threeDManager.materialHandler = {};
  
  viewportManager.threeDManager.materialHandler.getMaterial = function (properties, geometryObject, version, overrideColor, vertexColors) {
    let materials = [];
    for (let i = 0, len = _apis.length; i < len; i++)
      materials.push(_apis[i].threeDManager.materialHandler.getMaterial(properties, geometryObject, version, overrideColor, vertexColors));
    return materials;
  };
  
};

module.exports = ViewportManagerThreeDManager;