class ArEvent {
  constructor(type, api, optional) {
    this.type = type;
    this.api = api;
    if (optional) {
      if (optional.image) {
        this.image = optional.image;
      }
      if (optional.anchors) {
        this.anchors = optional.anchors;
      }
      if (optional.tracking) {
        this.tracking = optional.tracking;
      }
      if (optional.trackingReason) {
        this.trackingReason = optional.trackingReason;
      }
      if (optional.worldMapping) {
        this.worldMapping = optional.worldMapping;
      }
      if (optional.runtimeId) {
        this.runtimeId = optional.runtimeId;
      }
      if (optional.anchorId) {
        this.anchorId = optional.anchorId;
      }
    }
  }
}

module.exports = ArEvent;
