/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *DefaultDispatching.js*
 *
 * ### Content
 *   * Default message dispatching configuration of ViewerApp
 *
 * @module DefaultDispatching
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Imported messaging constants
 */
var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
 * Imported dispatching constants
 */
var dispatchingConstants = require('./DispatchingConstants');

/**
 * Imported dispatching config constructor
 */
var DispatchingConfig = require('./DispatchingConfig');

////////////
////////////
//
// Default message dispatching configuration
//
////////////
////////////

/**
 * Constructor of the DefaultDispatching mixin
 * @mixin DefaultDispatching
 * @author Alex Schiftner <alex@shapediver.com>
 *
 * @param {Object} references - References to other managers
 * @param {Object} references.pluginManager - Reference to the plugin manager
 * @param {Object} references.parameterManager - Reference to the parameter manager
 */
var DefaultDispatching = function(_refs) {

  // Create default dispatching configuration
  var defaultDispatching = new DispatchingConfig();

  // listener for parameter registration messages
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PARAMETER_REGISTER,
    messagingConstants.messageDataTypes.PARAMETER_DEFINITION,
    _refs.parameterManager.registerMultipleParameters,
    [
      dispatchingConstants.callbackParamTypes.DATA,
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );

  // listener for app settings that might be part of the PARAMETER_REGISTER message
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PARAMETER_REGISTER,
    messagingConstants.messageDataTypes.PARAMETER_SETTINGS,
    _refs.parameterManager.registerConfig,
    [
      dispatchingConstants.callbackParamTypes.DATA,
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.EXPORT_REGISTER,
    messagingConstants.messageDataTypes.PARAMETER_SETTINGS,
    _refs.exportManager.registerConfig,
    [
      dispatchingConstants.callbackParamTypes.DATA,
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );

  // listener for plugin status ACTIVE messages - this triggers the first loading of the scene (unless prohibited by plugin settings)
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PLUGIN_ACTIVE,
    messagingConstants.messageDataTypes.PLUGIN_RUNTIME_ID,
    _refs.parameterManager.refreshPluginInitial,
    [
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );

  ////////////
  ////////////
  //
  // Export manager
  //
  ////////////
  ////////////

  // listener for export registration messages
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.EXPORT_REGISTER,
    messagingConstants.messageDataTypes.EXPORT_DEFINITION,
    _refs.exportManager.registerMultipleExports,
    [
      dispatchingConstants.callbackParamTypes.DATA,
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );

  ////////////
  ////////////
  //
  // Scene manager
  //
  ////////////
  ////////////

  // listener for initializing a subscene loading process
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.SCENE_SUBSCENE_INIT,
    messagingConstants.messageDataTypes.SUBSCENE_INIT,
    _refs.sceneManager.initSubScene,
    [
      dispatchingConstants.callbackParamTypes.DATA,
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );

  // listener for adding a new json output version to a subscene
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.SCENE_SUBSCENE_OUTPUTVERSION,
    messagingConstants.messageDataTypes.SUBSCENE_OUTPUTVERSION_JSON,
    _refs.sceneManager.addJSONOutputVersionToSubScene,
    [
      dispatchingConstants.callbackParamTypes.DATA,
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );

  // listener for adding a new output version to a subscene
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.SCENE_SUBSCENE_OUTPUTVERSION,
    messagingConstants.messageDataTypes.SUBSCENE_OUTPUTVERSION_OBJ,
    _refs.sceneManager.addOutputVersionToSubScene,
    [
      dispatchingConstants.callbackParamTypes.DATA,
      dispatchingConstants.callbackParamTypes.PLUGIN,
      dispatchingConstants.callbackParamTypes.TOKEN
    ]
  );

  ////////////
  ////////////
  //
  // Process status handler
  //
  ////////////
  ////////////

  // listener for process messages
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_STATUS,
    _refs.processStatusHandler.updateProcessStatus
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_SUCCESS,
    _refs.processStatusHandler.unregisterProcess
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_ABORT,
    _refs.processStatusHandler.unregisterProcess
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_ERROR,
    _refs.processStatusHandler.unregisterProcess
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_FORK,
    _refs.processStatusHandler.forkProcessStatus
  );

  ////////////
  ////////////
  //
  // Container handler
  //
  ////////////
  ////////////

  // listener for process messages
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_STATUS,
    _refs.viewportVisibilityHandler.messageReceiver
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_SUCCESS,
    _refs.viewportVisibilityHandler.messageReceiver
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_ERROR,
    _refs.viewportVisibilityHandler.messageReceiver
  );
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.PROCESS,
    messagingConstants.messageDataTypes.PROCESS_FORK,
    _refs.processStatusHandler.forkProcessStatus
  );

  ////////////
  ////////////
  //
  // Settings handler
  //
  ////////////
  ////////////

  // listener for settings update messages
  defaultDispatching.addPartListener(
    messagingConstants.messageTopics.SETTINGS_REGISTER,
    messagingConstants.messageDataTypes.APP_SETTINGS,
    _refs.settingsHandler.messageReceiver.bind(_refs.settingsHandler)
  );

  return defaultDispatching.getConfig();
};

// export dispatching config
module.exports = DefaultDispatching;
