/**
 * @file Creates a shared interface for all LightApis.
 *       The LightApiInterface must be implemented by all LightApis.
 *       All described methods must be implemented.
 *
 * @module LightApiInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the LightApi.
 *
 * @ignore//@interface LightApiInterface
 */
class LightApiInterface {

  /**
   * The unique ID for a specific light. Each light within a light scene must have a unique ID.
   * When saving light scenes the unique IDs of individual lights will get saved as well, i.e. when
   * restoring light scenes the IDs of the individual lights will be restored as well.
   *
   * @typedef {String} module:LightApiInterface~LightApiInterface#LightID
   */

  /**
   * Light type, see {@link  module:LightApiInterface~LightApiInterface#LIGHTTYPE} for supported values
   * @typedef {String} module:LightApiInterface~LightApiInterface#LIGHTTYPE
   */



  /**
   * The properties of a light. When specifying a {@link module:LightApiInterface~LightApiInterface#LightDefinition LightDefinition} the {@link module:LightApiInterface~LightApiInterface#LIGHTTYPE type} and the {@link module:LightApiInterface~LightApiInterface#LightProperties properties} have to be of same type. 
   * @see module:LightApiInterface~LightApiInterface#AmbientLightProperties 
   * @see module:LightApiInterface~LightApiInterface#DirectionalLightProperties 
   * @see module:LightApiInterface~LightApiInterface#HemisphereLightProperties 
   * @see module:LightApiInterface~LightApiInterface#PointLightProperties 
   * @see module:LightApiInterface~LightApiInterface#SpotLightProperties 
   * @see module:LightApiInterface~LightApiInterface#FlashLightProperties 
   * @typedef {Object} module:LightApiInterface~LightApiInterface#LightProperties
   */

  /**
   * @typedef {module:LightApiInterface~LightApiInterface#LightProperties} module:LightApiInterface~LightApiInterface#AmbientLightProperties
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [color=0xffffff] the color of the light
   * @property {Number} [intensity=0.5] the intensity of the light
   */

  /**
   * @typedef {module:LightApiInterface~LightApiInterface#LightProperties} module:LightApiInterface~LightApiInterface#DirectionalLightProperties
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [color=0xffffff] the color of the light
   * @property {Number} [intensity=0.5] the intensity of the light
   * @property {Boolean} [castShadow=false] option to cast shadows from this light
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [direction={x: -1, y: 0, z: 1}] the direction of the light, will be normalized internally
   */

  /**
   * @typedef {module:LightApiInterface~LightApiInterface#LightProperties} module:LightApiInterface~LightApiInterface#HemisphereLightProperties
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [skyColor=0xffffff] the sky color of the light
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [groundColor=0xffffff] the ground color of the light
   * @property {Number} [intensity=0.5] the intensity of the light
   */

  /**
   * @typedef {module:LightApiInterface~LightApiInterface#LightProperties} module:LightApiInterface~LightApiInterface#PointLightProperties
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [color=0xffffff] the color of the light
   * @property {Number} [intensity=0.5] the intensity of the light
   * @property {Number} [distance=0] maximum range of the light; default is 0 (no limit)
   * @property {Number} [decay=2] the amount the light dims along the distance of the light
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [position] the position of the light; if no position is given, the position is set to the border of the bounding sphere
   */

  /**
   * @typedef {module:LightApiInterface~LightApiInterface#LightProperties} module:LightApiInterface~LightApiInterface#SpotLightProperties
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [color=0xffffff] the color of the light
   * @property {Number} [intensity=0.5] the intensity of the light
   * @property {Number} [distance=0] maximum range of the light; default is 0 (no limit)
   * @property {Number} [angle=PI/4] maximum angle of light dispersion from its direction whose upper bound is PI/2
   * @property {Number} [penumbra=0.5] percent of the spotlight cone that is attenuated due to penumbra; takes values between zero and 1
   * @property {Number} [decay=1] the amount the light dims along the distance of the light
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [position] the position of the light; if no position is given, the position is set to the border of the bounding sphere
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [target] the target of the light; if no target is given, the target is set to the center of the scene
   */

  /**
   * @typedef {module:LightApiInterface~LightApiInterface#LightProperties} module:LightApiInterface~LightApiInterface#FlashLightProperties
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [color=0xffffff] the color of the light
   * @property {Number} [intensity=0.4] the intensity of the light
   * @property {Number} [distance=0] maximum range of the light; default is 0 (no limit)
   * @property {Number} [angle=PI/2] maximum angle of light dispersion from its direction whose upper bound is PI/2
   * @property {Number} [penumbra=5] percent of the spotlight cone that is attenuated due to penumbra; takes values between zero and 1
   * @property {Number} [decay=5] the amount the light dims along the distance of the light
   */


  /**
   * The definition of a light. Varies per type.
   * @typedef {Object} module:LightApiInterface~LightApiInterface#LightDefinition
   * @property {module:LightApiInterface~LightApiInterface#LightID} id the id of the light, if not provided an id unique to the current light scene will be created
   * @property {module:LightApiInterface~LightApiInterface#LIGHTTYPE} type the type of the light
   * @property {module:LightApiInterface~LightApiInterface#LightProperties} properties the specific properties of the light
   */

  /**
   * The unique ID for a specific light scene.
   * For predefined light scenes see {@link  module:LightApiInterface~LightApiInterface#LIGHTSCENE}
   * @typedef {String} module:LightApiInterface~LightApiInterface#LightSceneID
   */

  /**
   * The definition of a light scene.
   *
   * @typedef {Object} module:LightApiInterface~LightApiInterface#LightSceneDefinition
   * @property {module:LightApiInterface~LightApiInterface#LightSceneID} id the id of the light scene, if not provided a unique id will be created
   * @property {module:LightApiInterface~LightApiInterface#LightDefinition[]} lights the lights in the scene
   */




  /**
   * The constructor for the {@link module:LightApiInterface~LightApiInterface}.
   * @constructs module:LightApiInterface~LightApiInterface
   */
  constructor() {

    /**
     * Enum for supported light types.
     * @readonly
     * @enum {module:LightApiInterface~LightApiInterface#LIGHTTYPE}
     */
    this.LIGHTTYPE = {
      /**  ambient light */
      AMBIENT : 'ambient',
      /**  directional light */
      DIRECTIONAL : 'directional',
      /** point light */
      HEMISPHERE : 'hemisphere',
      /** point light */
      POINT : 'point',
      // /** rectangle area light */
      // RECTAREA : 'rectarea',
      /** spot light */
      SPOT : 'spot',
      /** flash light */
      FLASH : 'flash',
    };

    /**
     * Enum for predefined light scenes.
     * @readonly
     * @enum {module:LightApiInterface~LightApiInterface#LIGHTSCENE}
     */
    this.LIGHTSCENE = {
      /** the default light scene */
      DEFAULT: 'default',
      /** the legacy light scene */
      LEGACY: 'legacy'
    };

  }

  /**
   * Add a light to the current light scene.
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightDefinition} definition Definition of light to add to the current light scene. May include an id to be used for the new light, which must be unique.
   *                                     A unique id will be automatically created and returned if none is provided.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data of type {@link module:LightApiInterface~LightApiInterface#LightID}: Unique id of newly added light in the current light scene, falsy if light could not be added
   */
  updateLight(definition) {}

  /**
   * Remove light from the current light scene,
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightID} id unique id of the light to be removed from the current light scene
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data of type boolean: true on success, false if light could not be removed
   */
  removeLight(id) {}

  /**
   * Returns the light in the current light scene with this id.
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightID} id unique id of the light to be return from the current light scene
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data of type {@link module:LightApiInterface~LightApiInterface#LightDefinition}: the definition of the light with the given id
   */
  getLight(id) {}

  /**
   * Replaces the current light scene with the light scene defined by the given id.
   * Predefined light scenes may be restored using this function.
   * In case the light scene with the given id does not exist, the current light scene will stay untouched.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneID} id
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data of type boolean: true if light scene could be set, false if not
   */
  setLightSceneFromID(id) {}

  /**
   * Replaces the current light scene with the given lights.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneDefinition} scene
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data of type {@link module:LightApiInterface~LightApiInterface#LightSceneID}: Unique id of the newly set light scene, falsy if new light scene could not be set
   */
  setLightSceneFromDefinition(scene) {}

  /**
   * Return definition of the light scene with the provided id. If no id is provided, it returns the current light scene. Does not change the current light scene.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneID} [id] id of light scene whose definition should be returned,
   *                the definition of the current light scene will be returned if no id is provided
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data of type {@link module:LightApiInterface~LightApiInterface#LightSceneDefinition}: definition of light scene on success, falsy if light scene with provided id does not exist
   */
  getLightScene(id) {}

  /**
   * Returns the name of all available light scenes.
   *
   * @abstract
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data array of type {@link module:LightApiInterface~LightApiInterface#LightSceneID} an array of all stored light scenes
   */
  getAllLightScenes() {}

  /**
   * Save the current light scene either to a new {@link module:LightApiInterface~LightApiInterface#LightSceneID} or to the one provided.
   * CAUTION: IDs of predefined light scenes may not be used for saving.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneID} [id] Unique id to use for saving the current light scene, potentially
   *                 overwriting the saved definition of an existing light scene. May be empty, in which case a new unique id will be created.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} Api Response with data array of type {@link module:LightApiInterface~LightApiInterface#LightSceneID} unique id which was used for saving the current light scene, falsy on error
   */
  saveLightScene(id) {}
}

module.exports = LightApiInterface;
