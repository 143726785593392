/**
*
* Supersample Anti-Aliasing Render Pass
*
* Main implementation by bhouston, adaption for this project by Michael Oppitz.
*
* This manual approach to SSAA re-renders the scene ones for each sample with camera jitter and accumulates the results.
*
* References: https://en.wikipedia.org/wiki/Supersampling
*
* @module SSAAPass
* @author bhouston / http://clara.io/
* @author Michael Oppitz
*/

let THREE = require('../../../externals/three');
let Shaders = require('../shaders/ShaderFile');

/**
 * Constructor of the SSAAPass
 * @class
 * @param {Object} ___settings - Instantiation settings
 * @param {Number} ___settings.width Width of the renderer
 * @param {Number} ___settings.height Height of the renderer
 */
var SSAAPass = function (___settings) {

  /**
   * The properties of the copy shader.
   */
  let _copyShader = {
    uniforms: {
      tDiffuse: { value: null },
      opacity: { value: 1.0 }
    },
    attributes: ['position', 'uv'],
    vertexShader: Shaders.basic_vert,
    fragmentShader: Shaders.copy_frag
  };

  /**
   * Creation of the copy shader with the ShaderMaterial.
   * @type {THREE.ShaderMaterial}
   */
  let _copyMaterial = new THREE.ShaderMaterial(_copyShader);
  _copyMaterial.premultipliedAlpha = true;
  _copyMaterial.transparent = true;
  _copyMaterial.blending = THREE.AdditiveBlending;
  _copyMaterial.depthTest = false;
  _copyMaterial.depthWrite = false;

  /**
   * Creation of a full-screen quad scene to render textures to.
   */
  let _camera2 = new THREE.OrthographicCamera(- 1, 1, 1, - 1, 0, 1);
  let _scene2 = new THREE.Scene();
  let _quad2 = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), _copyMaterial);
  _quad2.frustumCulled = false; // Avoid getting clipped
  _scene2.add(_quad2);

  /**
   * The render target for the computations.
   * @type {THREE.WebGLRenderTarget}
   */
  let _sampleRenderTarget = new THREE.WebGLRenderTarget(___settings.width, ___settings.height, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat });
  _sampleRenderTarget.texture.name = 'SSAAPass.sample';

  /**
   * These jitter vectors are specified in integers because it is easier.
   * I am assuming a [-8,8) integer grid, but it needs to be mapped onto [-0.5,0.5)
   * before being used, thus these integers need to be scaled by 1/16.
   *
   * Sample patterns reference: https://msdn.microsoft.com/en-us/library/windows/desktop/ff476218%28v=vs.85%29.aspx?f=255&MSPPError=-2147217396
   */
  let _jitterVectors = [
    [
      [0, 0]
    ],
    [
      [4, 4], [- 4, - 4]
    ],
    [
      [- 2, - 6], [6, - 2], [- 6, 2], [2, 6]
    ],
    [
      [1, - 3], [- 1, 3], [5, 1], [- 3, - 5],
      [- 5, 5], [- 7, - 1], [3, 7], [7, - 7]
    ],
    [
      [1, 1], [- 1, - 3], [- 3, 2], [4, - 1],
      [- 5, - 2], [2, 5], [5, 3], [3, - 5],
      [- 2, 6], [0, - 7], [- 4, - 6], [- 6, 4],
      [- 8, 0], [7, - 4], [6, 7], [- 7, - 8]
    ],
    [
      [- 4, - 7], [- 7, - 5], [- 3, - 5], [- 5, - 4],
      [- 1, - 4], [- 2, - 2], [- 6, - 1], [- 4, 0],
      [- 7, 1], [- 1, 2], [- 6, 3], [- 3, 3],
      [- 7, 6], [- 3, 6], [- 5, 7], [- 1, 7],
      [5, - 7], [1, - 6], [6, - 5], [4, - 4],
      [2, - 3], [7, - 2], [1, - 1], [4, - 1],
      [2, 1], [6, 2], [0, 4], [4, 4],
      [2, 5], [7, 5], [5, 6], [3, 7]
    ]
  ];

  ////////////
  ////////////
  //
  // SSAAPass API
  //
  ////////////
  ////////////

  /**
   * Renders the SSAAPass.
   * Depending on the sample size, there are multiple rendering that have a small offset.
   * These renderings are combined to achieve anti-aliasing.
   *
   * @param {THREE.WebGLRenderer} renderer The renderer
   * @param {THREE.Scene} scene The scene
   * @param {THREE.PerspectiveCamera} camera The camera
   * @param {THREE.WebGLRenderTarget} renderTarget The render target to render into
   * @param {Number} samples The number of samples
   */
  this.render = function (renderer, scene, camera, renderTarget, samples) {
    _quad2 = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), _copyMaterial);
    _quad2.frustumCulled = false; // Avoid getting clipped
    _scene2.children.length = 0;
    _scene2.add(_quad2);

    // Compute the offsets depending on the sample level
    let jitterOffsets = _jitterVectors[Math.max(0, Math.min(samples, 5))];

    // Store the original renderer settings
    let autoClear = renderer.autoClear;
    renderer.autoClear = false;
    let oldClearColor = renderer.getClearColor().getHex();
    let oldClearAlpha = renderer.getClearAlpha();

    // Set everything up for rendering
    let baseSampleWeight = 1.0 / jitterOffsets.length;
    let roundingRange = 1 / 32;
    _copyMaterial.uniforms.tDiffuse.value = _sampleRenderTarget.texture;

    // render the scene multiple times, each slightly jitter offset from the last and accumulate the results.
    jitterOffsets.forEach(function(jitterOffset, i) {
      let sampleWeight = baseSampleWeight;

      if (camera.setViewOffset)
        camera.setViewOffset(renderTarget.width, renderTarget.height, jitterOffset[0] * 0.0625, jitterOffset[1] * 0.0625, renderTarget.width, renderTarget.height);

      // the theory is that equal weights for each sample lead to an accumulation of rounding errors.
      // The following equation varies the sampleWeight per sample so that it is uniformly distributed
      // across a range of values whose rounding errors cancel each other out.
      let uniformCenteredDistribution = (- 0.5 + (i + 0.5) / jitterOffsets.length);
      sampleWeight += roundingRange * uniformCenteredDistribution;

      // Render into the _sampleRenderTarget
      _copyMaterial.uniforms.opacity.value = sampleWeight;
      renderer.setClearColor(oldClearColor, oldClearAlpha);
      renderer.render(scene, camera, _sampleRenderTarget, true);

      // On the first pass set the clear color to 0x000000 and the alpha to 0
      if (i === 0)
        renderer.setClearColor(0x000000, 0.0);

      renderer.render(_scene2, _camera2, renderTarget, (i === 0));
    });

    _scene2.remove(_quad2);
    _quad2.geometry.dispose();
    _quad2 = undefined;

    // Reset everything
    if (camera.clearViewOffset) camera.clearViewOffset();
    renderer.autoClear = autoClear;
    renderer.setClearColor(oldClearColor, oldClearAlpha);
  };

  /**
   * Dispose the render target.
   */
  this.dispose = function () {
    if (_sampleRenderTarget) {
      _sampleRenderTarget.dispose();
      _sampleRenderTarget = null;
    }
  };

  /**
   * Change the size of the render target.
   *
   * @param {Number} width The new width
   * @param {Number} height The new height
   */
  this.setSize = function (width, height) {
    _sampleRenderTarget.setSize(width, height);
  };

};

module.exports = SSAAPass;
