/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewportVisibilityHandler.js*
 *
 * ### Content
 *   * Functionality for managing the DOM container used to display the viewer
 *
 * @module ViewportVisibilityHandler
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Import constants
 */
var viewerAppConstants = require('../ViewerAppConstants');

/**
 * Import message prototype
 */
var MessagePrototype = require('../../shared/messages/MessagePrototype');

/**
 * Import global utils
 */
let GlobalUtils = require('../../shared/util/GlobalUtils');

let StateDashboardLibrary = require('../../modules/shapediver-state-dashboard/StateDashboard').StateDashboardLibrary;

var THREE = require('../../externals/three');

/**
  * Constructor of the ViewportVisibilityHandler mixin
  * @mixin ViewportVisibilityHandler
  * @author Alex Schiftner <alex@shapediver.com>
  */
var ViewportVisibilityHandler = function(___settings) {

  var that = this;

  var _viewerApi;
  var _viewerApp = ___settings.viewerApp;
  var _viewportManager = ___settings.viewportManager;
  var _sceneGeometryManager = ___settings.sceneGeometryManager;

  // Remember whether we have faded in the scene at least once
  var _shownBefore = false;

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * Report whether the scene is visible currently
   * @return {Boolean} true if scene is visible, false if not
   */
  _o.isVisible = function() {
    return that.getSettingShallow('show');
  };

  /**
    * Show (fade in) or hide (fade out) the scene
    * @public
    * @param {Boolean} [bShow=true] Show (default) or hide the scene behind the container
    */
  _o.showScene = function(bShow) {
    if ( bShow === false ){
      _viewportManager.api.updateSettingAsync('show', false);
      if ( _o.isVisible() ) {
        let m = new MessagePrototype(viewerAppConstants.messageDataTypes.GENERIC, {
          shownBefore: _shownBefore
        });
        that.message(viewerAppConstants.messageTopics.SCENE_VISIBILITY_OFF, m);
      }
    } else {
      if(!_viewerApi) _viewerApi = _viewerApp.api({ version: 2 });
      let bbData = _viewerApi.scene.getBoundingBox().data;
      if(bbData.min.x === Infinity || bbData.min.y === Infinity || bbData.min.z === Infinity || bbData.max.x === -Infinity || bbData.max.y === -Infinity || bbData.max.z === -Infinity) return;

      _viewportManager.api.updateSettingAsync('show', true);

      // in case of no stored settings, the camera should be zoomed to extents
      if (!_shownBefore && !that.getSetting('hasRestoredSettings')) {
        let apis = _viewportManager.getApis();

        for(let i = 0; i < apis.length; i++) {
          let api = apis[i];
          let cd = api.getSetting('camera.defaults.perspective');
          if(!(cd.position.x === 0 && cd.position.y === 0 && cd.position.z === 0 && 
            cd.target.x === 0 && cd.target.y === 0 && cd.target.z === 0)) {
            api.camera.resetAsync({duration: 0});
          } else {
            // we set the camera to a corner position so that zoomExtents is not just on top
            api.camera.updateAsync({ position: { x: 0, y: -7.5, z: 5 }, target: { x: 0, y: 0, z: 0} }, {duration: 0}).then(() => {
              // zoom to the scene bounding box + 1/4
              let bbData = _viewerApi.scene.getBoundingBox().data;
              let bb = new THREE.Box3(new THREE.Vector3(bbData.min.x, bbData.min.y, bbData.min.z), new THREE.Vector3(bbData.max.x, bbData.max.y, bbData.max.z));
              bb.expandByVector(bb.getSize().multiplyScalar(1/4));
              api.camera.zoomAsync(bb, {duration: 0, default: true});
            });
          }      
        }
      }

      if(!_shownBefore) {
        let dashboard = StateDashboardLibrary.getInstance(_viewerApi.getSetting('viewerRuntimeId'));
        dashboard.firstTimeVisible.resolve(true);
      }
      
      if ( !_o.isVisible() ) {
        let m = new MessagePrototype(viewerAppConstants.messageDataTypes.GENERIC, {
          shownBefore: _shownBefore
        });
        that.message(viewerAppConstants.messageTopics.SCENE_VISIBILITY_ON, m);
      }
      
      _shownBefore = true;
    }

  };

  /**
    * Hide the scene
    * @public
    */
  _o.hideScene = function() {
    that.updateSetting('show', false);
  };

  /**
    * Hook for updateSetting of showSceneTransition,
    * @private
    * @see {@link module:SettingsMixin~SettingsMixin#registerHook}
    * @see {@link module:SettingsMixin~SettingsMixin#updateSetting}
    * @param {String} showSceneTransition - new transition settings to be used
    */
  var _showSceneTransitionHook = function(v) {
    if ( !GlobalUtils.typeCheck(v, 'string') )
      return false;

    _viewportManager.api.updateSettingAsync('showSceneTransition', v);
    return true;
  };

  /**
    * Hook for updateSetting of showScene,
    * @private
    * @see {@link module:SettingsMixin~SettingsMixin#registerHook}
    * @see {@link module:SettingsMixin~SettingsMixin#updateSetting}
    * @param {Boolean} toggle - true if scene should be shown, false if scene should be hidden
    */
  var _showSceneHook = function(v) {
    if ( !GlobalUtils.typeCheck(v, 'boolean') )
      return false;

    let g = _sceneGeometryManager.getGeometryNode();
    if(g.children.length === 0)
      return false;


    _o.showScene(v);
    return true;
  };

  /**
    * Install the hooks
    */
  this.registerHook('showSceneTransition', _showSceneTransitionHook);
  this.registerHook('show', _showSceneHook);

  /**
    * Container for tokens of initial loading processes
    */
  var _initTokens = {};

  /**
   * Receiver for messages telling about the start and status of initial geometry loading processes.
   *
   * @param  {String|module:MessagingConstants~MessageToken} token - Unique token of the process
   * @param  {module:MessagingConstants~ProcessStatusMessage} data - data of message (unused by this function)
   * @param  {module:MessagingConstants~MessageDataTypes} type - type of message (process start, success, error)
   */
  _o.messageReceiver = function(token, data, type) {
    let scope = 'ViewportVisibilityHandler.messageReceiver';
    // no need to fade in the scene twice
    if ( _shownBefore )
      return;
    // sanity check
    data = data || {};
    // register initial loading processes
    if ( type === viewerAppConstants.messageDataTypes.PROCESS_STATUS && data.progress === 0 ) {
      _initTokens[token.id] = token;
    }
    // check for success of initial loading processes
    else if ( type === viewerAppConstants.messageDataTypes.PROCESS_SUCCESS) {
      if ( _initTokens.hasOwnProperty(token.id) ) {
        delete _initTokens[token.id];
        let m = that.getSettingShallow('showSceneMode');
        if ( m === viewerAppConstants.showSceneModes.ON_FIRST_PLUGIN ||
          (Object.keys(_initTokens).length === 0 && m === viewerAppConstants.showSceneModes.ON_ALL_PLUGINS) ) {
          that.updateSetting('show', true);
        }
      }
    }
    else if ( type === viewerAppConstants.messageDataTypes.PROCESS_ERROR ) {
      that.debug(scope, 'Received PROCESS_ERROR for initial geometry loading process, not yet showing 3D scene', token);
    }
    else if ( type === viewerAppConstants.messageDataTypes.PROCESS_FORK ) {
      if ( _initTokens.hasOwnProperty(token.id) ) {
        let oldtoken = _initTokens[token.id];
        for (let t in data) {
          let forkedtoken = GlobalUtils.deepCopy(oldtoken);
          forkedtoken.id = t;
          _initTokens[t] = forkedtoken;
        }
        delete _initTokens[token.id];
      }
    }
  };

  /**
   * Run initial tasks - this should get called at the very end of initializing the complete viewer app.
   */
  _o.init = function() {
    // initial setup of container
    if (that.getSetting('showSceneMode') === viewerAppConstants.showSceneModes.INSTANT) {
      that.updateSetting('show', true);
    } else {
      that.updateSetting('show', false);
    }
    // make sure to set the transition style not before the next run of the event loop
    setTimeout( () => {
      that.updateSetting('showSceneTransition', that.getSetting('showSceneTransition'));
    }, 0);
  };

  return _o;
};

module.exports = ViewportVisibilityHandler;
