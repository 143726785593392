// verb is nasty to be included in the closure build process
// make verb-nurbs appear as an external library for google closure
// after compilation we concatenate node_modules/verb-nurbs//build/js/verb.min.js (size roughly 136830) with the viewer
var o;
try {
  // in the browser verb will already have been loaded at this stage
  o = eval('verb');
} catch (e) {
  if ( typeof require === 'function' ) {
    // we use eval to require for development, because this require will not be seen by closure
    o = eval('require(\'verb-nurbs\')');
  }
}
module.exports = o;
