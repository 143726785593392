/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiStateInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer State API V2.1
 *
 * @module ApiStateInterface
 * @author ShapeDiver <contact@shapediver.com>
 */

////////////
////////////
//
// State Interface
//
////////////
////////////

/**
  * Viewer state description
  * @typedef {Object} module:ApiStateInterface~ApiStateInterface#ViewerAppState
  * @property {Boolean} busy - true if the viewer is in busy mode, false if the viewer is in idle mode
  * @property {Number} progress - minimum progress of all processes currently reporting a progress, interval [0,1]
  * @property {Object.<String,Object>} processes - List of currently active processes
  * @property {Array} failedStatusMessages - array of status messages corresponding to fatal errors
  */

/**
  * Status event type, see {@link module:ApiStateInterface~ApiStateInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiStateInterface~ApiStateInterface#StateEventType
  */

/**
 * ### ShapeDiver Viewer - State API
 *
 * The state interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
 *
 * It allows to
 *  * get an overview of the current state of the viewer instance,
 *  * influence the state of the viewer instance and its visual representation,
 *  * add and remove listeners for related events.
 * @interface ApiStateInterface
 */
var ApiStateInterface = function () {

  /**
   * Enum for supported state event types.
   * @readonly
   * @enum {@link module:ApiStateInterface~ApiStateInterface#StateEventType}
   */
  this.EVENTTYPE = {
    /** The viewer has entered busy state, which means at least one update process is currently going on. */
    BUSY: 'busy',
    /** The viewer has entered idle state, which means there are no more update processes going on. */
    IDLE: 'idle',
    /** a message which may be displayed to the user */
    MESSAGE: 'message',
    /** a message reporting a fatal error, e.g. the WebGL context was lost and could not be recovered  */
    FAILED: 'failed',
  };

  /**
   * Retrieve the current state of the viewer
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with data of type {@link @link module:ApiStateInterface~ApiStateInterface#ViewerAppState}. Description of the current state of the viewer application.
   */
  this.get = function () { };

  /**
   * Register the API as a busy process of the viewer app
   * @param  {Boolean} busy - If true, a busy process will be registered, causing the viewer app to enter busy mode. If false and a busy process of the API had been registered before, it will be removed.
   * @param  {Number} [progress] - Set the percentage of completion in interval [0,1]. If progress bars are displayed, this will influence them.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with data of type {@link @link module:ApiStateInterface~ApiStateInterface#ViewerAppState}. Description of the current state of the viewer application.
   */
  this.setBusy = function () { };

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:ApiStateInterface~ApiStateInterface#StateEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiStateInterface~ApiStateInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function () { };

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function () { };
};

module.exports = ApiStateInterface;