let ViewportManager = function (viewerApp, loggingHandler) {

  const GLOBAL_UTILS = require('../../shared/util/GlobalUtils'),
        MESSAGE_PROTOTYPE = require('../../shared/messages/MessagePrototype'),
        MESSAGING_CONSTANTS = require('../../shared/constants/MessagingConstants'),
        THREE = require('../../externals/three'),
        _apis = [];

  let that,
      _initialized = false,
      _firstBB,
      _viewerApp,
      _viewerApi,
      _loggingHandler;

  class ViewportManager {
    constructor(viewerApp, loggingHandler) {
      that = this;
      _viewerApp = viewerApp;
      _loggingHandler = loggingHandler;

      GLOBAL_UTILS.inject({
        message: _viewerApp.message,
        subscribeToMessageStream: _viewerApp.subscribeToMessageStream,
        unsubscribeFromMessageStream: _viewerApp.unsubscribeFromMessageStream
      }, that);

      let token = that.subscribeToMessageStream(MESSAGING_CONSTANTS.messageTopics.SCENE_VISIBILITY_ON, function (/*topic, message*/) {
        that.unsubscribeFromMessageStream(token);
        _firstBB = _viewerApp.sceneGeometryManager.getGeometryNode().computeSceneBoundingBox();
        _initialized = true;

        for (let i = 0, len = _apis.length; i < len; i++) {
          _viewerApp.settingsHandler.restoreSettings(_apis[i]);
          _apis[i].threeDManager.init(_firstBB);
          if (_viewerApp.getSetting('show') === true)
            _apis[i].updateSettingAsync('show', true);
        }
      });


      require('./ViewportManagerThreeDManager')(that, _apis);
      require('./ViewportManagerApi')(that, _apis);
    }


    _convertInputObject(input) {
      /**
       * can come as:
       *  1. as single object (see 1)
       *  2. as single dom
       */
      let container, runtimeId;

      if (input.container) {
        if (input.container instanceof Element) {
          container = input.container;
        } else {
          return;
        }

        let currentId = input.runtimeId;
        if (currentId) {
          let used = false;
          for (let j = 0, len = _apis.length; j < len; j++)
            if (_apis[j].getViewportRuntimeId() === currentId)
              used = true;

          runtimeId = !used ? currentId : GLOBAL_UTILS.createRandomId();
        } else {
          runtimeId = GLOBAL_UTILS.createRandomId();
        }
      } else {
        if (input instanceof Element) {
          container = input;
          runtimeId = GLOBAL_UTILS.createRandomId();
        } else {
          return;
        }
      }

      return {
        container: container,
        runtimeId: runtimeId,
      };
    }

    _createThreeDManager(object) {
      let threeDManager, api;

      if (!_viewerApi)
        _viewerApi = _viewerApp.api({ version: 2 });

      if (!object)
        return null;

      threeDManager = new (require('../viewport/ThreeDManager'))(_viewerApi,
        {
          scene: _viewerApp.sceneGeometryManager.getScene(),
          geometryNode: _viewerApp.sceneGeometryManager.getGeometryNode(),
          pathUtils: _viewerApp.sceneGeometryManager.getPathUtils(),
          container: object.container,
          runtimeId: object.runtimeId,
          canvasFullSize: object.canvasFullSize,
          loggingHandler: _loggingHandler,
          messagingHandler: {
            message: _viewerApp.message,
            subscribeToMessageStream: _viewerApp.subscribeToMessageStream,
            unsubscribeFromMessageStream: _viewerApp.unsubscribeFromMessageStream
          },
          interactionGroupManager: _viewerApp.interactionGroupManager,
          viewportManager: that
        }
      );

      api = new (require('../viewport/api/ViewportApi'))(_viewerApi,
        {
          threeDManager: threeDManager,
          viewportManager: that,
        }
      );

      // only happens for the first viewport
      if (!_viewerApi.camera && !_viewerApi.lights) {
        _viewerApi.camera = api.camera;
        _viewerApi.lights = api.lights;
      }

      api.threeDManager = threeDManager;
      threeDManager.runtimeId = object.runtimeId;
      threeDManager.container = object.container;
      threeDManager.api = api;
      threeDManager.startUpObject = object;
      threeDManager.transformationMatrix = new THREE.Matrix4();
      threeDManager.transformationGroups = [];
      return api;
    }

    createThreeDManager(inputObject) {
      if (!inputObject)
        return null;
      if (!Array.isArray(inputObject))
        inputObject = [inputObject];

      let newApis = [];
      for (let i = 0, len1 = inputObject.length; i < len1; i++) {
        let input = that._convertInputObject(inputObject[i]);
        input.canvasFullSize = _viewerApp.getSetting('canvasFullSize');

        let api = that._createThreeDManager(input);
        _apis.push(api);

        if (api.threeDManager.success === false) {
          // no need to send a further log message, this should have been handled in the constructor of the ThreeDManager

          // call this before adding somthing to the container, otherwise it will be removed again
          that.removeThreeDManager(api.getViewportRuntimeId());

          continue;
        }

        api.updateSettingAsync('show', false);
        api.updateSettingAsync('showSceneTransition', _viewerApp.getSetting('showSceneTransition'));

        let obj = _viewerApp.sceneGeometryManager.getMeshMaterialObjects(),
            mesh, properties, hasTextureCoordinates, overrideColor, vertexColors;

        for (let j = 0, len2 = obj.length; j < len2; j++) {
          let o = obj[j];
          mesh = o.mesh;
          properties = o.properties;
          hasTextureCoordinates = o.hasTextureCoordinates;
          overrideColor = o.overrideColor;
          vertexColors = o.vertexColors;

          let mat = api.threeDManager.materialHandler.getMaterial(properties, overrideColor, vertexColors);
          if (mat === null || mat === undefined) {
            api.threeDManager.warn('The creation of a material failed.');
            continue;
          }

          if (!hasTextureCoordinates) {
            mat.map = null;
            mat.alphaMap = null;
            mat.aoMap = null;
            mat.bumpMap = null;
            mat.displacementMap = null;
            mat.emissiveMap = null;
            mat.normalMap = null;
            mat.metalnessMap = null;
            mat.roughnessMap = null;
          }
          api.threeDManager.addMesh(mesh, mat, properties);
        }

        if (api) {
          // send creation message
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC, { 
            viewportApi: api,
            viewportRuntimeId: api.threeDManager.runtimeId
          });
          that.message(MESSAGING_CONSTANTS.messageTopics.VIEWPORTS_CREATED, m);

          newApis.push(api);
          if (_initialized) {
            _viewerApp.settingsHandler.restoreSettings(api);
            api.threeDManager.init(_firstBB);

            if (_viewerApp.getSetting('show') === true)
              api.updateSettingAsync('show', true);
          }
        }
      }
      return newApis;
    }

    removeThreeDManager(runtimeId) {
      for (let i = 0, len = _apis.length; i < len; i++) {
        if (_apis[i].getViewportRuntimeId() === runtimeId) {
          let api = _apis[i];
          api.threeDManager.destroy();
          for (let key in api)
            delete api[key];
          _apis.splice(i, 1);


          // send creation message
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC, { 
            viewportRuntimeId: runtimeId
          });
          that.message(MESSAGING_CONSTANTS.messageTopics.VIEWPORTS_DESTROYED, m);
          
          return true;
        }
      }
      return false;
    }

    reloadThreeDManager(runtimeId) {
      for (let i = 0, len = _apis.length; i < len; i++) {
        if (_apis[i].getViewportRuntimeId() === runtimeId) {
          let api = _apis[i];
          let properties = Object.assign({}, api.threeDManager.startUpObject);
          that.removeThreeDManager(api.getViewportRuntimeId());
          _apis.splice(i, 1);
          api = that.createThreeDManager(properties)[properties.runtimeId];
          return;
        }
      }
    }

    getContainers() {
      let container = [];
      for (let i = 0, len = _apis.length; i < len; i++)
        container.push(_apis[i].getContainer());
      return container;
    }

    getApi(runtimeId) {
      for (let i = 0, len = _apis.length; i < len; i++)
        if (runtimeId === _apis[i].getViewportRuntimeId())
          return _apis[i];
    }

    getApis() {
      return _apis;
    }
  }

  return new ViewportManager(viewerApp, loggingHandler);
};

module.exports = ViewportManager;
