/**
 * @file Creates a shared interface for all LightHandlers.
 *       The LightHandlerInterface must be implemented by all LightHandlers.
 *       All described methods must be implemented.
 * 
 * @module LightHandlerInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the LightHandler.
 * The LightHandler implements all functionality related to the lights.
 * 
 * //@interface LightHandlerInterface
 */
class LightHandlerInterface {
  /**
   * The constructor for the {@link module:LightHandlerInterface~LightHandlerInterface}.
   * @constructs module:LightHandlerInterface~LightHandlerInterface
   */
  constructor() { }

  /**
   * Pause the light updates.
   *
   * @abstract
   */
  pause() { }

  /**
   * Resume the light updates.
   *
   * @abstract
   */
  resume() { }

  /**
   * Destroy the lightHandler.
   *
   * @abstract
   */
  destroy() { }




  /**
   * Add a light to the current light scene.
   * @abstract
   * @param {LightDefinition} definition Definition of light to add to the current light scene. May include an id to be used for the new light, which must be unique.
   *                                     A unique id will be automatically created and returned if none is provided.
   * @returns {LightID} Unique id of newly added light in the current light scene, falsy if light could not be added
   */
  updateLight(definition) {}

  /**
   * Remove light from the current light scene,
   * @abstract
   * @param {LightID} id unique id of the light to be removed from the current light scene
   * @returns {Boolean} true on success, false if light could not be removed
   */
  removeLight(id) {}

  /**
   * Returns the light in the current light scene with this id.
   * @abstract
   * @param {LightID} id unique id of the light to be return from the current light scene
   * @returns {LightDefinition} the definition of the light with the given id
   */
  getLight(id) {}

  /**
   * Replaces the current light scene with the light scene defined by the given id.
   * Predefined light scenes may be restored using this function.
   * In case the light scene with the given id does not exist, the current light scene will stay untouched.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneID|module:LightApiInterface~LightApiInterface#LIGHTSCENE} id
   * @returns {Boolean} true if light scene could be set, false if not
   */
  setLightSceneFromID(id) {}

  /**
   * Replaces the current light scene with the given lights.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneDefinition} scene
   * @returns {module:LightApiInterface~LightApiInterface#LightSceneID} Unique id of the newly set light scene, falsy if new light scene could not be set
   */
  setLightSceneFromDefinition(scene) {}

  /**
   * Return definition of the light scene with the provided id. If no id is provided, it returns the current light scene. Does not change the current light scene.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneID|module:LightApiInterface~LightApiInterface#LIGHTSCENE} [id] id of light scene whose definition should be returned,
   *                the definition of the current light scene will be returned if no id is provided
   * @returns {module:LightApiInterface~LightApiInterface#LightSceneDefinition} definition of light scene on success, falsy if light scene with provided id does not exist
   */
  getLightScene(id) {}

  /**
   * Returns the name of all available light scenes.
   *
   * @abstract
   * @returns {module:LightApiInterface~LightApiInterface#LightSceneID|module:LightApiInterface~LightApiInterface#LIGHTSCENE[]} an array of all stored light scenes
   */
  getAllLightScene() {}

  /**
   * Save the current light scene either to a new {@link module:LightApiInterface~LightApiInterface#LightSceneID} or to the one provided.
   * CAUTION: IDs of predefined {@link module:LightApiInterface~LightApiInterface#LIGHTSCENE}s may not be used for saving.
   *
   * @abstract
   * @param {module:LightApiInterface~LightApiInterface#LightSceneID} [id] Unique id to use for saving the current light scene, potentially
   *                 overwriting the saved definition of an existing light scene. May be empty, in which case a new unique id will be created.
   * @returns {module:LightApiInterface~LightApiInterface#LightSceneID} unique id which was used for saving the current light scene, falsy on error
   */
  saveLightScene(id) {}




  /**
   * Updates the position of the first directional light according to the bounding sphere.
   *
   * @abstract
   * @param {THREE.Sphere} bs The bounding sphere around the scene
   */
  adjustToBoundingSphere(bs) { }

  /**
  * IMPORTANT: This function must only be called from RenderingHandler.Hook->shadows.
  * Toggles the shadows of the lights.
  *
  * @abstract
  * @param {Boolean} toggle To what to set the mode
  * @returns {Boolean} If the mode was successfully set
  */
  setToggleLightShadows(toggle) { }

  /**
   * Toggles all lights in the scene.
   *
   * @abstract
   * @param {Boolean} toggle To what to set the mode
   */
  toggleLights(toggle) { }
}

module.exports = LightHandlerInterface;