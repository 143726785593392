/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *InteractionGroupManager.js*
 *
 * ### Content
 *   * Management of the interaction groups in the 3D scene
 *
 * @module InteractionGroupManager
 * @author Michael Oppitz <michael@shapediver.com>
 */

let InteractionGroupManager = function(){
  const THREE = require('../externals/three'),
        TO_TINY_COLOR = require('../shared/util/toTinyColor'),
        DEFAULT_HIGHLIGHTS = require('./viewport/defaults/DefaultHighlights');

  let that,
      _groups = [];

  class InteractionGroupManager {

    /**
     * Constructor of the Interaction Group Handler
     * @class
     */
    constructor() {
      that = this;
    }

    /**
     * An interaction group.
     *
     * @param {Object} properties
     * @param {String} name - unique identifier of the group
     * @param {Boolean} [selectable=false] - objects belonging to the group are selectable
     * @param {Boolean} [draggable=false] - objects belonging to the group are draggable
     * @param {Boolean} [hoverable=false] - objects belonging to the group are hoverable
     * @param {Object} [selectableHighlight] - highlighting effect to apply to selectable objects - #SS-921
     * @param {Object} [draggableHighlight] - highlighting effect to apply to draggable objects - #SS-921
     * @param {Object} [hoverableHighlight] - highlighting effect to apply to hoverable objects - #SS-921
     * @param {String} [selectionMode='single'] - allowed values:
     *                     'single' - a single object of the group can be selected,
     *                        all objects of the group are deselected on a click which does not hit an object of the group;
     *                     'multiple' - selection status of objects belonging to the group is toggled by clicking on them,
     *                        selection status does not change on a click which does not hit an object of the group
     */
    InteractionGroup(properties) {
      var _groupOut = {};

      let _name = properties.name;
      let _selectable = properties.selectable || false;
      let _draggable = properties.draggable || false;
      let _hoverable = properties.hoverable || false;
      let _selectionMode = properties.selectionMode || 'single';

      /**
       * Possible highlight structure
       * - if a valid name is given, that default highlight with the corresponding name is chosen as the highlight (DefaultHighlights.js)
       * - if no name, the apply and remove functions are used if available
       *
       * active: {
       *   name: String,
       *   options: {},
       *   apply: function (object, options) { },
       *   remove: function (object, options) { },
       * },
       * passive: {
       *   name: String,
       *   options: {},
       *   apply: function (object, options) { },
       *   remove: function (object, options) { },
       * }
       *
       * @param {Object} highlight
       */
      let _adaptHighlight = function (highlight) {

        if (!highlight)
          return null;

        // if we got apply and remove functions already, use them
        if (typeof highlight.apply === 'function' && typeof highlight.remove === 'function')
          return highlight;

        // if we didn't get a name, choose a default name
        if (!highlight.name)
          highlight.name = 'opacityHighlight';

        if (highlight.name == 'opacityHighlight') {
          highlight.apply = DEFAULT_HIGHLIGHTS[0].apply;
          highlight.remove = DEFAULT_HIGHLIGHTS[0].remove;

          if (!highlight.options) {
            highlight.options = { opacity: .5 };
          } else if (!highlight.options.opacity) {
            highlight.options.opacity = .5;
          }
        } else if (highlight.name == 'colorHighlight') {
          highlight.apply = DEFAULT_HIGHLIGHTS[1].apply;
          highlight.remove = DEFAULT_HIGHLIGHTS[1].remove;

          if (!highlight.options) {
            highlight.options = { color: new THREE.Color(1, 0, 0) };
          } else if (!highlight.options.color) {
            highlight.options.color = new THREE.Color(1, 0, 0);
          } else {
            highlight.options.color = TO_TINY_COLOR(highlight.options.color, 'red').toThreeColor();
          }
        }

        if (!highlight.apply || !highlight.remove)
          return null;

        return highlight;
      };

      let _selectableHighlight = {
        active: (properties.selectableHighlight && properties.selectableHighlight.active) ? _adaptHighlight(properties.selectableHighlight.active) : null,
        passive: (properties.selectableHighlight && properties.selectableHighlight.passive) ? _adaptHighlight(properties.selectableHighlight.passive) : null,
      };
      let _draggableHighlight = {
        active: (properties.draggableHighlight && properties.draggableHighlight.active) ? _adaptHighlight(properties.draggableHighlight.active) : null,
        passive: (properties.draggableHighlight && properties.draggableHighlight.passive) ? _adaptHighlight(properties.draggableHighlight.passive) : null,
      };
      let _hoverableHighlight = {
        active: (properties.hoverableHighlight && properties.hoverableHighlight.active) ? _adaptHighlight(properties.hoverableHighlight.active) : null,
        passive: (properties.hoverableHighlight && properties.hoverableHighlight.passive) ? _adaptHighlight(properties.hoverableHighlight.passive) : null,
      };

      /**
       * Returns the name of the group.
       *
       * @returns {String} the name
       */
      _groupOut.getName = function () {
        return _name;
      };

      /**
       * Returns the boolean for selectable of the group.
       *
       * @returns {boolean}
       */
      _groupOut.isSelectable = function () {
        return _selectable;
      };

      /**
       * Returns the boolean for draggable of the group.
       *
       * @returns {boolean}
       */
      _groupOut.isDraggable = function () {
        return _draggable;
      };

      /**
       * Returns the boolean for hoverable of the group.
       *
       * @returns {boolean}
       */
      _groupOut.isHoverable = function () {
        return _hoverable;
      };

      /**
       * Returns the string for the selection mode of the group.
       *
       * @returns {string}
       */
      _groupOut.getSelectionMode = function () {
        return _selectionMode;
      };

      /**
       * Returns the highlight for selectable of the group.
       *
       * @returns {Object}
       */
      _groupOut.getSelectableHighlight = function () {
        return _selectableHighlight;
      };

      /**
       * Returns the highlight for draggable of the group.
       *
       * @returns {Object}
       */
      _groupOut.getDraggableHighlight = function () {
        return _draggableHighlight;
      };

      /**
       * Returns the highlight for hoverable of the group.
       *
       * @returns {Object}
       */
      _groupOut.getHoverableHighlight = function () {
        return _hoverableHighlight;
      };

      return _groupOut;
    }

    ////////////
    ////////////
    //
    // InteractionGroupManager API
    //
    ////////////
    ////////////


    /**
     * Adds a new interaction group if the name was not used already.
     *
     * @param {Object} properties
     * @returns {boolean}
     */
    addInteractionGroup(properties) {
      if(properties.name && !that.isInteractionGroup(properties.name)){
        _groups.push(new that.InteractionGroup(properties));
        return true;
      }
      return false;
    }

    /**
     * Adds or replaces an interaction group with the given name.
     *
     * @param {Object} properties
     * @returns {boolean}
     */
    addOrReplaceInteractionGroup(properties) {
      _groups = _groups.filter(function(group) {
        return group.getName() !== properties.name;
      });
      return that.addInteractionGroup(properties);
    }

    /**
     * Checks for an interaction group with the given name.
     *
     * @param {String} name
     * @returns {boolean}
     */
    isInteractionGroup(name) {
      if(that.getGroup(name) == null)
        return false;
      return true;
    }

    /**
     * Returns an interaction group with the given name.
     *
     * @param {String} name
     * @returns {Object}
     */
    getGroup(name) {
      for(let i = 0, len = _groups.length; i < len; i++){
        if(_groups[i].getName() == name)
          return _groups[i];
      }
      return null;
    }

    /**
     * Returns all interaction groups.
     *
     * @returns {Object[]}
     */
    getGroups() {
      return _groups;
    }

  }

  return new InteractionGroupManager();
};

module.exports = InteractionGroupManager;
